import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  IGetListCV,
  IListCV,
  IQueryListCV,
  ITableList,
  ListCVBase,
} from "services/cv/interfaces";
import { Mapper } from "services/cv/paramsMapper";
import Search, { IQueryDomain } from "./Search";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../configuration/reduxToolkit/hooks";
import {
  setLoading,
  setAlert,
  selectCountErrors,
} from "../../../reduxFeatures/app/appSlice";
import Header from "../../Header";
import TableCV from "./Table";
import { tableListMapper } from "services/cv/tableListMapper";
import { useNavigate } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ConfgiPDF } from "./pdf";
import ErrorMessage from "components/ErrorMessage";
import { DataListMapper } from "services/cv/mappers";
import { FactoryDeletePersonalInformationCV } from "services/cv/personalInformation/remotePersonalInformationDeleteCV";
import DialogConfirm from "./DialogConfirm";

type Props = {
  getListCV: IGetListCV;
};

const PARAMS_INIT = {
  pageNo: 0,
  pageSize: 10,
};

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ListCV = ({ getListCV }: Props) => {
  const dispatch = useAppDispatch();
  const countErrors = useAppSelector(selectCountErrors);
  const navigate = useNavigate();
  const [params, setParams] = useState<IQueryListCV>(PARAMS_INIT);
  const [data, setData] = useState<IListCV>({} as IListCV);
  const [dataTable, setDataTable] = useState<ITableList[]>([]);
  const [showSkeletonSearch, setShowSkeletonSearch] = useState<boolean>(true);
  const [openModelConfirm, setOpenModelConfirm] = useState<boolean>(false);
  const [cvSelectedDelete, setCvSelectedDelete] = useState<ListCVBase>();

  const columns = [
    { field: "name", headerName: "Nome", flex: 1, sortable: false },
    { field: "role", headerName: "Cargo", flex: 1, sortable: false },
    { field: "email", headerName: "E-mail", flex: 1, sortable: false },
    { field: "company", headerName: "Empresa Atual", flex: 1, sortable: false },
    {
      field: "dateInit",
      headerName: "Data de inicio",
      flex: 1,
      sortable: false,
    },
  ];

  const getCVBySelectedId = (id: string): ListCVBase[] =>
    data.content.filter((item) => item.id === id);

  const handleSearch = (query: IQueryDomain) => {
    setShowSkeletonSearch(false);
    setParams({ ...params, ...Mapper.toModel(query), pageNo: 0 });
  };

  const handleClearSearch = () => setParams(PARAMS_INIT);

  const handleEdit = (id: string) => {
    const [cvSelected]: ListCVBase[] = getCVBySelectedId(id);
    navigate(`curriculo/edit/${id}`, { state: cvSelected });
  };

  const handlePrint = async (id: string) => {
    const [cvSelected]: ListCVBase[] = getCVBySelectedId(id);
    const convertSelected = DataListMapper.oneToDomain(cvSelected);

    const value = ConfgiPDF(convertSelected);

    pdfMake.createPdf(value).open();
  };

  const handleDelete = async (id: string) => {
    const [cvSelected]: ListCVBase[] = getCVBySelectedId(id);
    setCvSelectedDelete(cvSelected);
    setOpenModelConfirm(true);
  };

  const handleNewPage = (pageNo: number) => {
    setShowSkeletonSearch(false);
    setParams({ ...params, pageNo });
  };

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      dispatch(setLoading(true));
      getListCV
        .get(params)
        .then((t) => {
          setData(t);
          setDataTable(tableListMapper.toTableList(t));
          dispatch(setLoading(false));
        })
        .catch((e) => {
          dispatch(setLoading(false));
          dispatch(setAlert({ type: "error", open: true, message: e.message }));
        });
    }
  }, [dispatch, params, getListCV]);

  const handleCloseModel = async (value: boolean) => {
    if (!value) setOpenModelConfirm(false);
    if (cvSelectedDelete) {
      try {
        dispatch(setLoading(true));
        await FactoryDeletePersonalInformationCV.delete(cvSelectedDelete.id);
        setOpenModelConfirm(false);
        setCvSelectedDelete(undefined);
        setParams({ ...params, ...PARAMS_INIT });
        dispatch(setLoading(false));
        dispatch(
          setAlert({
            type: "success",
            open: true,
            message: "Currículo excluído com sucesso.",
          })
        );
      } catch (error: any) {
        setCvSelectedDelete(undefined);
        setOpenModelConfirm(false);
        dispatch(setLoading(false));
        dispatch(
          setAlert({ type: "error", open: true, message: error.message })
        );
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Header title={"Lista de CV`s"} />

      {countErrors > 0 ? (
        <ErrorMessage />
      ) : (
        <>
          <DialogConfirm
            handleOnclose={handleCloseModel}
            open={openModelConfirm}
          />
          <Search
            handleSearch={handleSearch}
            handleClear={handleClearSearch}
            showSkeletonSearch={showSkeletonSearch}
          />
          <TableCV
            rows={dataTable}
            columns={columns}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handlePrint={handlePrint}
            pageSize={data.pageSize}
            currentPage={data.pageNo}
            totalElements={data.totalElements}
            handleNewPage={handleNewPage}
          />
        </>
      )}
    </Box>
  );
};

export default ListCV;
