import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

type Props = {
  message?: string;
  showButtonReload?: boolean;
  showMessageTi?: boolean;
};

const ErrorMessage = ({
  message,
  showButtonReload = true,
  showMessageTi = true,
}: Props) => {
  const defaultMessage =
    "Ocorreram multiplos erros na aplicação, clique no botão para recarregar a página.";

  const handleOnClick = () => window.location.reload();

  return (
    <Grid container sx={{ mt: "1.5rem", minHeight: "400px" }}>
      <Grid item xs={12}>
        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant="body2"
            mx="auto"
            sx={{ mt: "1rem" }}
            fontSize={16}
          >
            {message ? message : defaultMessage}
          </Typography>
          {showMessageTi ? (
            <Typography
              variant="body2"
              mx="auto"
              sx={{ mt: "1rem" }}
              fontSize={14}
            >
              Se o erro persistir, favor entrar em contato com a TI.
            </Typography>
          ) : null}
          {showButtonReload ? (
            <Button
              variant="contained"
              color="dbDarkBlue"
              sx={{ mt: "1rem" }}
              onClick={handleOnClick}
            >
              Recarregar
            </Button>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ErrorMessage;
