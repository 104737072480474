import { Box, Button, Divider } from "@mui/material";
import ConditionalStepForm from "components/CV/Form/ConditionalStepForm";
import Header from "components/CV/Form/Header";
import Knowledge from "components/CV/Form/Knowledge";
import Languages from "components/CV/Form/Languages";
import { useAppSelector } from "configuration/reduxToolkit/hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading, setAlert } from "../../../../reduxFeatures/app/appSlice";
import { resetCountErros, selectCountErrors } from "reduxFeatures/app/appSlice";
import {
  IKnowledgeFront,
  ILanguagesFront,
  IProficienciesFront,
  IKnowledge,
  ILanguages,
} from "services/cv/interfaces";
import { PostProficiencies } from "services/cv/proficiencies/postProficiencies";
import { ProficienciesMapper } from "services/cv/mappers/proficienciesMapper";
import { PutProficiencies } from "services/cv/proficiencies/putProficiencies";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  cvId: string;
  knowledge?: IKnowledgeFront[];
  languages?: ILanguagesFront[];
  // TODO: Remover stepForm e setStepForm quando rotas individuais estiverem sendo usadas
  stepsForm: number;
  setStepsForm: React.Dispatch<React.SetStateAction<number>>;
};

export const KNOWLEDGE_INIT: IKnowledgeFront = {
  active: false,
  id: "",
  profile: {
    description: "",
    id: "",
    language: "",
  },
  technology: {
    description: "",
    id: "",
    language: "",
  },
};

const numberStep = 2;

const Step2 = ({
  cvId,
  knowledge,
  languages,
  stepsForm,
  setStepsForm,
}: Props) => {
  const { saveProficiencies } = PostProficiencies();
  const { updateProficiencies } = PutProficiencies();
  const { pathname } = useLocation();
  // TODO: Descomentar quando as rotas estiverem sendo usadas
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const countErrors = useAppSelector(selectCountErrors);

  const [localKnowledge, setKnowledge] = useState<IKnowledgeFront[]>(
    knowledge && knowledge.length ? knowledge : []
  );

  const [localLanguages, setLanguages] = useState<ILanguagesFront[]>(
    languages && languages.length ? languages : []
  );

  const handleNextStepForm = async () => {
    dispatch(setLoading(true));
    try {
      const proficiencies: IProficienciesFront = {
        knowledges: localKnowledge,
        languages: localLanguages,
        step: 1,
      };
      const proficiencias = ProficienciesMapper.toModel(proficiencies);

      if (pathname === `/curriculo/edit/${cvId}`) {
        const createdProficiencies = await updateProficiencies(
          cvId,
          proficiencias
        );

        setKnowledge(
          createdProficiencies.conhecimentos.map((conhecimento: IKnowledge) => {
            return {
              id: conhecimento.id,
              technology: {
                id: conhecimento.tecnologia.id,
                description: conhecimento.tecnologia.descricao,
                language: conhecimento.tecnologia.linguagem,
              },
              profile: {
                id: conhecimento.perfil.id,
                description: conhecimento.perfil.descricao,
                language: conhecimento.perfil.linguagem,
              },
              active: conhecimento.ativo,
            };
          })
        );

        setLanguages(
          createdProficiencies.linguas.map((lingua: ILanguages) => {
            return {
              id: lingua.id,
              language: {
                id: lingua.lingua.id,
                description: lingua.lingua.descricao,
                language: lingua.lingua.linguagem,
              },
              level: {
                id: lingua.nivel.id,
                description: lingua.nivel.descricao,
                language: lingua.nivel.linguagem,
              },
              active: lingua.ativo,
            };
          })
        );
      } else {
        await saveProficiencies(cvId, proficiencias);
      }
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          open: true,
          message: "Proficiências salvas com sucesso.",
          type: "success",
          seconds: 2000,
        })
      );
      setStepsForm(stepsForm + 1);
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          open: true,
          message:
            "Não foi possível adicionar as proficiências! Por favor, tente novamente.",
          type: "error",
          seconds: 2000,
        })
      );
    }
  };

  const handlePrevStepForm = () => {
    dispatch(resetCountErros());
    setStepsForm(stepsForm - 1);
  };

  return (
    <ConditionalStepForm
      show={numberStep == stepsForm}
      showError={countErrors > 0}
      messageError="Ocorreu um erro inesperado ao preencher os dados na tela."
    >
      <Header maxSteps={5} currentStep={stepsForm} />

      <Divider />

      <Knowledge
        knowledge={localKnowledge}
        setKnowledge={setKnowledge}
        knowledgeSelectedInit={KNOWLEDGE_INIT}
      />

      <Languages languages={localLanguages} setLanguages={setLanguages} />

      <Divider />

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          mt: "1rem",
        }}
      >
        <Box sx={{ display: "flex", columnGap: 1 }}>
          <Button
            variant="contained"
            type="button"
            color="dbPink"
            onClick={handlePrevStepForm}
            data-cy="anterior"
          >
            Anterior
          </Button>
          <Button
            variant="contained"
            type="button"
            color="dbLightBlue"
            onClick={handleNextStepForm}
            disabled={
              countErrors > 0 ||
              localKnowledge.length === 0 ||
              localLanguages.length === 0
            }
            data-cy="proximo"
          >
            Próximo
          </Button>
        </Box>
      </Box>
    </ConditionalStepForm>
  );
};

export default Step2;
