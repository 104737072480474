export const validateDateEnd = (value: string | undefined, situationValue: string | undefined) => {
  const isEmpty = !value || value.toString().trim() === "";

  const concludedSituations = ["7b635342-3e18-11ed-b878-0242ac120002", "7b634fd2-3e18-11ed-b878-0242ac120002"];
  const incompleteSituations = ["7b6355fe-3e18-11ed-b878-0242ac120002", "7b635220-3e18-11ed-b878-0242ac120002",
    "7b635108-3e18-11ed-b878-0242ac120002", "7b634bae-3e18-11ed-b878-0242ac120002"];

  if (situationValue && concludedSituations.includes(situationValue) && isEmpty) {
    return "Campo obrigatório, se Concluído";
  } else if (situationValue && incompleteSituations.includes(situationValue) && !isEmpty) {
    return "Campo permitido apenas se Concluído";
  }
};

export const dateEndIsSmallerThanDateInit = (dateEndvalue: string, dateInitValue: string) => {
  const dateEnd = new Date(dateEndvalue);
  const dateInit = new Date(dateInitValue);
  if (dateEnd <= dateInit && dateEndvalue) {
    return "Data final deve ser posterior à data inicial"
  }
};

export const validateStatusToAllowdateEnd = (dateEnd:string, statusValue: boolean | undefined) =>{
   const isEmpty = !dateEnd || dateEnd.toString().trim() === "";
   if(statusValue === false && isEmpty) {
    return "Campo obrigatório caso a experiência seja do passado.";
  }else if(statusValue && !isEmpty ){
    return "Campo deve ser vazio, caso seja emprego atual."
  }

}
