import {
  ICertifications,
  ICertificationsFront,
  IEducations,
  IEducationsFront,
  IExperiences,
  IExperiencesFront,
  IKnowledge,
  IKnowledgeFront,
  ILanguages,
  ILanguagesFront,
  IPersonalInformation,
  IPersonalInformationFront,
  ListCVBase,
  ListCVBaseFront,
} from "../interfaces";
import { CertificationMapper } from "./certificationMapper";
import { EducationMapper } from "./educationMapper";
import { ExperiencesMapper } from "./experiencesMapper";
import { KnowledgeMapper } from "./knowledgeMapper";
import { LanguagesMapper } from "./languagesMapper";
import { PersonalInformationMapper } from "./personalInformationMapper";

interface IDataListMapper {
  allToDomain: (data: ListCVBase[]) => ListCVBaseFront[];
  allToModel: (data: ListCVBaseFront[]) => ListCVBase[];
  oneToDomain: (data: ListCVBase) => ListCVBaseFront;
  oneToModel: (data: ListCVBaseFront) => ListCVBase;
}

const convertObjectModelToDomain = (data: ListCVBase): ListCVBaseFront => {
  const {
    dadosPessoais,
    conhecimentos,
    experiencias,
    certificacoes,
    educacoes,
    idiomas,
    dataCriacao,
    id,
    step,
  } = data;
  const personalInformation: IPersonalInformationFront =
    PersonalInformationMapper.toDomain(dadosPessoais);

  const knowledge: IKnowledgeFront[] = conhecimentos.map(
    KnowledgeMapper.toDomain
  );

  const experiences: IExperiencesFront[] = experiencias.map(
    ExperiencesMapper.toDomain
  );

  const certifications: ICertificationsFront[] = certificacoes.map(
    CertificationMapper.toDomain
  );

  const educations: IEducationsFront[] = educacoes.map(
    EducationMapper.toDomain
  );

  const languages: ILanguagesFront[] = idiomas.map(LanguagesMapper.toDomain);

  return {
    id,
    dateCreate: dataCriacao,
    personalInformation,
    knowledge,
    experiences,
    certifications,
    educations,
    languages,
    step,
  };
};

const convertObjectDomainToModel = (data: ListCVBaseFront): ListCVBase => {
  const {
    personalInformation,
    certifications,
    educations,
    experiences,
    knowledge,
    languages,
    dateCreate,
    id,
    step,
  } = data;
  const dadosPessoais: IPersonalInformation =
    PersonalInformationMapper.toModel(personalInformation);

  const conhecimentos: IKnowledge[] = knowledge.length
    ? knowledge.map(KnowledgeMapper.toModel)
    : [];

  const experiencias: IExperiences[] = experiences.length
    ? experiences.map(ExperiencesMapper.toModel)
    : [];

  const certificacoes: ICertifications[] = certifications.length
    ? certifications.map(CertificationMapper.toModel)
    : [];

  const educacoes: IEducations[] = educations.length
    ? educations.map(EducationMapper.toModel)
    : [];

  const idiomas: ILanguages[] = languages.length
    ? languages.map(LanguagesMapper.toModel)
    : [];

  return {
    id,
    dataCriacao: dateCreate,
    dadosPessoais,
    conhecimentos,
    experiencias,
    certificacoes,
    educacoes,
    idiomas,
    step,
  };
};

const convertModelToDomain = (data: ListCVBase[]): ListCVBaseFront[] =>
  data.map(convertObjectModelToDomain);

const convertDomainToModel = (data: ListCVBaseFront[]): ListCVBase[] =>
  data.map(convertObjectDomainToModel);

export const DataListMapper: IDataListMapper = {
  allToDomain: convertModelToDomain,
  allToModel: convertDomainToModel,
  oneToDomain: convertObjectModelToDomain,
  oneToModel: convertObjectDomainToModel,
};
