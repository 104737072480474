import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";

import Logo from "../logos/logo rosa.png";
import { IMenu } from "./interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

type Props = {
  menu: IMenu[];
  toggleDrawer: () => void;
};

const Header = ({ menu, toggleDrawer }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { instance, accounts } = useMsal();
  const [{ name }] = accounts;

  const handleLogout = () => {
    window.localStorage.clear();
    instance.logout();
  };

  return (
    <AppBar color="dbDarkBlue" component="nav">
      <Toolbar>
        <IconButton
          color="dbDarkBlue"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer}
          sx={[
            { mr: 2, display: { md: "none" } },
            (theme) => ({ color: theme.palette.dbPink.main }),
            (theme) => ({
              "&:hover": { color: theme.palette.dbPink.contrastText },
            }),
          ]}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 0, display: { xs: "none", lg: "flex" } }}>
          <img src={Logo} alt="Logo DBServer" width="74" height="74" />
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" }, ml: 5 }}>
          {menu.map((item) => (
            <Button
              key={item.name}
              sx={[
                {
                  fontWeight: "700",
                },
                (theme) => ({
                  "&:hover": {
                    color:
                      pathname === item.path
                        ? theme.palette.dbPink.main
                        : theme.palette.dbDarkBlue.contrastText,
                  },
                }),
                (theme) => ({
                  color:
                    pathname === item.path
                      ? theme.palette.dbPink.contrastText
                      : theme.palette.dbPink.main,
                }),
              ]}
              onClick={() => navigate(item.path)}
            >
              {item.label}
            </Button>
          ))}
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Typography
            variant="caption"
            noWrap
            sx={[
              {
                mr: 2,
                flexGrow: 1,
                fontWeight: 700,
                letterSpacing: ".3rem",

                textDecoration: "none",
              },
              (theme) => ({ color: theme.palette.dbPink.main }),
            ]}
          >
            {name}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 0, display: { xs: "none", lg: "flex" } }}>
          <Tooltip title="Sair">
            <IconButton sx={{ p: 0 }} onClick={handleLogout}>
              <LogoutIcon
                sx={[
                  (theme) => ({ color: theme.palette.dbPink.main }),
                  (theme) => ({
                    "&:hover": { color: theme.palette.dbPink.contrastText },
                  }),
                ]}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
