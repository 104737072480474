import { IQueryDomain } from "components/CV/List/Search";
import { formatDateToStringDateEN } from "shared/utils";
import { IQueryModel } from "./interfaces";

const domainToModel: { [key: string]: string } = {
  name: "nome",
  email: "email",
  role: "cargo",
  dateCreate: "dataCriacao",
  knowlegde: "conhecimentoId",
  language: "idiomaId",
  step: "step",
};

const modelToDomain: { [key: string]: string } = {
  nome: "name",
  email: "email",
  cargo: "role",
  dataCriacao: "dateCreate",
  conhecimentoId: "knowlegde",
  idiomaId: "language",
  step: "step",
};

export const Mapper = {
  toModel: (domain: IQueryDomain): IQueryModel => {
    const model: { [key: string]: string } = {};
    Object.keys(domain).forEach((key: string) => {
      const name: string = domainToModel[key as keyof IQueryDomain];
      const value: any = domain[key as keyof IQueryDomain];
      model[name] =
        name === "dataCriacao" ? formatDateToStringDateEN(value) : value;
    });
    return model;
  },
  toDomain: (model: IQueryModel): IQueryDomain => {
    const domain: { [key: string]: string } = {};
    Object.keys(model).forEach((key: string) => {
      const name: string = modelToDomain[key as keyof IQueryModel];
      const value: any = domain[key as keyof IQueryModel];
      domain[name] = value;
    });
    return domain;
  },
};
