import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../configuration/reduxToolkit/hooks";
import {
  setLoading,
  setAlert,
  selectLanguage,
  incrementCountErrors,
  decrementCountErros,
} from "../../../../reduxFeatures/app/appSlice";
import {
  Button,
  Divider,
  Grid,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { FactoryRemoteLanguages } from "services/combos/remoteLanguages";
import { FactoryRemoteKnowledge } from "services/combos/remoteKnowledge";

import {
  getConfigDateLocale,
  isEnvironment,
  LanguageTextByAcronym,
} from "shared/utils";
import { IBaseCombosFront } from "services/combos/interfaces";
import { ComboMapper } from "services/combos/comboMapper";
import SelectByObject from "components/SelectByObject";
import LoadingSkeleton from "components/LoadingSkeleton";

type Props = {
  handleSearch: (query: IQueryDomain) => void;
  handleClear: () => void;
  showSkeletonSearch: boolean;
};

export interface IQueryDomain {
  name?: string;
  email?: string;
  role?: string;
  dateCreate?: string | null;
  knowlegde?: string;
  language?: string;
}

const QUERY_INIT = {
  name: "",
  email: "",
  role: "",
  dateCreate: null,
  knowlegde: "",
  language: "",
};

const Search = ({ handleSearch, handleClear, showSkeletonSearch }: Props) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const languageName = LanguageTextByAcronym[language];
  const [listLanguage, setListLanguage] = useState<IBaseCombosFront[]>([]);
  const [listKnowledge, setListKnowledge] = useState<IBaseCombosFront[]>([]);
  const [query, setQuery] = useState<IQueryDomain>(QUERY_INIT);

  useEffect(() => {
    dispatch(setLoading(true));
    FactoryRemoteLanguages.get()
      .then((response) => {
        const listByLanguage = response.filter(
          (item) => item.linguagem.toLowerCase() === languageName
        );
        setListLanguage(ComboMapper.arrayModelToDomain(listByLanguage));
        dispatch(setLoading(false));
        dispatch(decrementCountErros());
      })
      .catch((e) => {
        if (isEnvironment("local")) {
          // setListLanguage(DEV_LANGUAGES);
        }
        dispatch(setLoading(false));
        dispatch(incrementCountErrors());
        dispatch(setAlert({ type: "error", open: true, message: e.message }));
      });
  }, [dispatch, languageName]);

  useEffect(() => {
    dispatch(setLoading(true));
    FactoryRemoteKnowledge.get()
      .then((response) => {
        const listByLanguage = response.filter(
          (item) => item.linguagem.toLowerCase() === languageName
        );
        setListKnowledge(ComboMapper.arrayModelToDomain(listByLanguage));
        dispatch(setLoading(false));
        dispatch(decrementCountErros());
      })
      .catch((e) => {
        if (isEnvironment("local")) {
          // setListKnowledge(DEV_KNOWLEDGE);
        }
        dispatch(setLoading(false));
        dispatch(incrementCountErrors());
        dispatch(setAlert({ type: "error", open: true, message: e.message }));
      });
  }, [dispatch, languageName]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setQuery({ ...query, [event.target.name]: event.target.value });

  const handleSelect = (event: SelectChangeEvent) =>
    setQuery({ ...query, [event.target.name]: event.target.value });

  const handleDateChange = (
    value: null,
    keyboardInputValue?: string | undefined
  ) => {
    setQuery({ ...query, dateCreate: value });
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    handleSearch(query);
  };

  const handleOnClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setQuery(QUERY_INIT);
    handleClear();
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getConfigDateLocale(language)}
    >
      <LoadingSkeleton quantiyItens={5} showSkeleton={showSkeletonSearch}>
        <Grid container spacing={2} sx={{ mt: "0.5rem" }}>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            sm={4}
            flexDirection="column"
          >
            <Grid item>
              <TextField
                fullWidth
                name="name"
                label="Nome"
                variant="outlined"
                size="small"
                onChange={handleInputChange}
                value={query.name}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name="email"
                label="E-mail"
                variant="outlined"
                size="small"
                onChange={handleInputChange}
                value={query.email}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            sm={4}
            flexDirection="column"
          >
            <Grid item>
              <TextField
                fullWidth
                name="role"
                label="Cargo"
                variant="outlined"
                size="small"
                onChange={handleInputChange}
                value={query.role}
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="Data Inserção"
                renderInput={(params: any) => (
                  <TextField size="small" fullWidth {...params} />
                )}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                onChange={handleDateChange}
                value={query.dateCreate}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            sm={4}
            flexDirection="column"
          >
            <Grid item>
              <SelectByObject
                name="knowlegde"
                label="Conhecimento"
                rows={listKnowledge}
                value={query.knowlegde}
                handleSelect={handleSelect}
                keyNameDescription="description"
                keyNameValueSelected="id"
              />
            </Grid>
            <Grid item>
              <SelectByObject
                name="language"
                label="Idioma"
                rows={listLanguage}
                value={query.language}
                handleSelect={handleSelect}
                keyNameDescription="description"
                keyNameValueSelected="id"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ mt: "0.5rem", mb: "1rem" }}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              fullWidth
              color="dbRed"
              variant="contained"
              onClick={handleOnClear}
              size="small"
            >
              Limpar
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              color="dbDarkBlue"
              variant="contained"
              onClick={handleOnClick}
              size="small"
            >
              Pesquisar
            </Button>
          </Grid>
        </Grid>

        <Divider />
      </LoadingSkeleton>
    </LocalizationProvider>
  );
};

export default Search;
