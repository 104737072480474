import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";

type Props = {
  label: string;
  name: string;
  rows: string[];
  control: Control<FieldValues> | any;
  disabled?: boolean;
  defaultValue?: string | undefined;
  optionClear?: string;
};

const SelectStringControl = ({
  control,
  label,
  name,
  rows,
  defaultValue = "",
  disabled,
  optionClear,
}: Props) => {
  if (rows.length && optionClear) {
    rows.push(optionClear);
  }
  return (
    <FormControl fullWidth size="small">
      <InputLabel id={`select-string-${label}`}>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            {...field}
            fullWidth
            id={`select-string-${label}`}
            label={label}
            sx={{ maxWidth: 1, width: "100%" }}
            MenuProps={{ disableScrollLock: true }}
            disabled={disabled}
          >
            {rows.map((item, index) => (
              <MenuItem
                data-cy={item}
                key={index}
                value={optionClear && item === optionClear ? "" : item}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default SelectStringControl;
