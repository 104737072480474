import {
  Box,
  Chip,
  FormHelperText,
  Grid,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FactoryRemoteKnowledge } from "services/combos/remoteKnowledge";
import { IKnowledgeFront } from "services/cv/interfaces";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../configuration/reduxToolkit/hooks";
import {
  setLoading,
  setAlert,
  decrementCountErros,
  incrementCountErrors,
  selectLanguage,
} from "../../../../reduxFeatures/app/appSlice";

import { FactoryRemoteKnowledgeProfile } from "services/combos/remoteKnowledgeProfile";
import { LanguageTextByAcronym } from "shared/utils";
import LoadingSkeleton from "../../../LoadingSkeleton";
import { useTranslation } from "react-i18next";
import { IBaseCombosFront } from "services/combos/interfaces";
import { ComboMapper } from "services/combos/comboMapper";
import SelectByObject from "components/SelectByObject";
import { KNOWLEDGE_INIT } from "pages/cv/create/step-2";

type Props = {
  setKnowledge: (l: IKnowledgeFront[]) => void;
  knowledge: IKnowledgeFront[];
  knowledgeSelectedInit: IKnowledgeFront;
};

const KNOWLEDGE_SELECTED_INIT: IBaseCombosFront = {
  description: "",
  id: "",
  language: "",
};

const PROFILE_SELECTED_INIT: IBaseCombosFront = {
  description: "",
  id: "",
  language: "",
};

const Knowledge = ({ setKnowledge, knowledge }: Props) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const languageName = LanguageTextByAcronym[language];
  const { t } = useTranslation();

  const [listKnowledge, setListKnowledge] = useState<IBaseCombosFront[]>([]);
  const [listProfiles, setListProfiles] = useState<IBaseCombosFront[]>([]);

  const [knowledgeSelected, setKnowledgeSelected] = useState<IBaseCombosFront>(
    KNOWLEDGE_SELECTED_INIT
  );
  const [profileSelected, setProfileSelected] = useState<IBaseCombosFront>(
    PROFILE_SELECTED_INIT
  );
  const [knowledgeOnEdit, setKnowledgeOnEdit] =
    useState<IKnowledgeFront>(KNOWLEDGE_INIT);

  const getDataCombos = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const kw = await FactoryRemoteKnowledge.get();
      const pr = await FactoryRemoteKnowledgeProfile.get();
      const kwLang = kw.filter(
        (item) => item.linguagem.toLowerCase() === languageName
      );
      const prLang = pr.filter(
        (item) => item.linguagem.toLowerCase() === languageName
      );
      setListKnowledge(ComboMapper.arrayModelToDomain(kwLang));
      setListProfiles(ComboMapper.arrayModelToDomain(prLang));
      dispatch(setLoading(false));
      dispatch(decrementCountErros());
    } catch (e: any) {
      dispatch(setLoading(false));
      dispatch(incrementCountErrors());
      dispatch(setAlert({ type: "error", open: true, message: e.message }));
    }
  }, [dispatch, languageName]);

  useEffect(() => {
    getDataCombos();
  }, [getDataCombos]);

  const handleChangeKnowledge = (event: SelectChangeEvent) => {
    const [selected] = listKnowledge.filter(
      (item) => item.id === event.target.value
    );

    const existsKnowledge = knowledge.some(
      (know) => {
        return know.technology.description === selected.description
      }
    );

    if (existsKnowledge && knowledgeOnEdit.id === "") {
      setKnowledgeSelected(KNOWLEDGE_SELECTED_INIT);
      setProfileSelected(PROFILE_SELECTED_INIT);
      dispatch(
        setAlert({
          open: true,
          message:
            "Já existe um conhecimento como esse adicionado. Por favor, selecione outro conhecimento.",
          type: "error",
          seconds: 3000,
        })
      );
    } else {
      setKnowledgeSelected(selected);
    }
  };

  const handleChangeProfile = (event: SelectChangeEvent) => {
    const [profileSelected] = listProfiles.filter(
      (item) => item.id === event.target.value
    );
    const existsKnowledge = knowledge.some(
      (know) =>
        know.technology.description === knowledgeSelected.description
    );

    if (!existsKnowledge && knowledgeOnEdit.id === "") {
      const newKnowledge: IKnowledgeFront = {
        id: "",
        active: true,
        technology: knowledgeSelected,
        profile: profileSelected,
      };
      setKnowledge([...knowledge, newKnowledge]);
      setKnowledgeSelected(KNOWLEDGE_SELECTED_INIT);
      setProfileSelected(PROFILE_SELECTED_INIT);
    }
    
    else if (existsKnowledge){
      try {
        const editedKnowledge = knowledge.findIndex(
          (knowledge) => {
            return knowledge.id === knowledgeOnEdit.id
          }
        );

        knowledge[editedKnowledge].technology = knowledgeSelected;
        knowledge[editedKnowledge].profile = profileSelected;

        setKnowledgeSelected(KNOWLEDGE_SELECTED_INIT);
        setProfileSelected(PROFILE_SELECTED_INIT);
        setKnowledgeOnEdit(KNOWLEDGE_INIT);
      } catch (error) {
        dispatch(
          setAlert({
            open: true,
            message: "Algo deu errado. Por favor, tente novamente.",
            type: "error",
            seconds: 3000,
          })
        );
        setKnowledgeSelected(KNOWLEDGE_SELECTED_INIT);
        setProfileSelected(PROFILE_SELECTED_INIT);
        setKnowledgeOnEdit(KNOWLEDGE_INIT);
      }
    }


  };

  const handleSetKnowledgeBack = (item: IKnowledgeFront) => {
    setKnowledgeSelected(item.technology);
    setProfileSelected(item.profile);
    setKnowledgeOnEdit(item);

    // TODO: sinalizar no chip qual conhecimento está sendo editado
  };

  const removeKnowledge = (item: IKnowledgeFront) => {
    dispatch(setLoading(true));
    try {
      setKnowledge([
        ...knowledge.filter(
          (know) =>
            !(
              know.technology.description === item.technology.description &&
              know.profile.description === item.profile.description
            )
        ),
      ]);

      dispatch(setLoading(false));
      dispatch(
        setAlert({
          open: true,
          message: "Conhecimento removido com sucesso.",
          type: "success",
          seconds: 2000,
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          message:
            "Não foi possível remover o conhecimento! Favor tentar novamente.",
          type: "error",
          seconds: 2000,
        })
      );
      dispatch(setLoading(false));
    }
  };

  return (
    <Box>
      <LoadingSkeleton quantiyItens={5}>
        <Grid container spacing={4} sx={{ mt: "0.5rem", mb: "2rem" }}>
          <Grid container item xs={6} spacing={2}>
            <Grid item xs={6} data-cy="select-conhecimento">
              <SelectByObject
                label={t("cvForm.labels.mainKnowlegde")}
                name="knowlegde"
                rows={listKnowledge}
                value={knowledgeSelected.id}
                handleSelect={handleChangeKnowledge}
                keyNameDescription="description"
                keyNameValueSelected="id"
              />
              <Box hidden={knowledge.length !== 0}>
                <FormHelperText
                  data-cy="texto-ajuda-conhecimento"
                  sx={{ ml: 1 }}
                >
                  Insira ao menos um conhecimento.
                </FormHelperText>
              </Box>
            </Grid>

            <Grid item xs={6} data-cy="perfil-conhecimento">
              <SelectByObject
                label={t("cvForm.labels.profile")}
                name="profile"
                rows={listProfiles}
                value={profileSelected.id}
                handleSelect={handleChangeProfile}
                disabled={knowledgeSelected.description === ""}
                keyNameDescription="description"
                keyNameValueSelected="id"
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={1}>
              {knowledge.map((item, index) => (
                <Chip
                  key={index}
                  label={`${item.technology.description}-${item.profile.description}`}
                  onDelete={() => removeKnowledge(item)}
                  onClick={() => handleSetKnowledgeBack(item)}
                  color="dbDarkBlue"
                  variant="outlined"
                  data-cy="conhecimento-e-perfil"
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </LoadingSkeleton>
    </Box>
  );
};

export default Knowledge;
