import { convertKeysObject } from "shared/utils";
import { IBaseCombos, IBaseCombosFront } from "./interfaces";

const domainToModel: { [key: string]: string } = {
  id: "id",
  description: "descricao",
  language: "linguagem",
};

const modelToDomain: { [key: string]: string } = {
  id: "id",
  descricao: "description",
  linguagem: "language",
};

export const ComboMapper = {
  arrayModelToDomain: (data: IBaseCombos[]): IBaseCombosFront[] => {
    return data.map((item) =>
      convertKeysObject(item, modelToDomain, {} as IBaseCombosFront)
    );
  },
  arrayDomainToModel: (data: IBaseCombosFront[]): IBaseCombos[] => {
    return data.map((item) =>
      convertKeysObject(item, domainToModel, {} as IBaseCombos)
    );
  },
};
