import { AxiosClient } from "configuration/axios/client";
import {
  HttpClient,
  HttpMessageError,
  HttpStatusCode,
} from "configuration/http/httpClient";
import { IPersonalInformation, IPutCV, ListCVBase } from "../interfaces";

export class RemotePersonalInformationPutCV implements IPutCV {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<ListCVBase>
  ) {}

  async put(id: string, data?: IPersonalInformation): Promise<ListCVBase> {
    const response = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "put",
      body: data,
    });
    const remoteData = response.body || ({} as ListCVBase);
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return remoteData;
      case HttpStatusCode.noContent:
        return {} as ListCVBase;
      case HttpStatusCode.badRequest:
        throw new Error(HttpMessageError[400]);
      default:
        throw new Error(HttpMessageError[500]);
    }
  }
}

const FactoryPutPersonalInformationCV: IPutCV =
  new RemotePersonalInformationPutCV(
    "/curriculos/dados-pessoais",
    new AxiosClient()
  );

export { FactoryPutPersonalInformationCV };
