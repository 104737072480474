import { AzureClientInstance } from "configuration";

export const refreshToken = async () => {
  const [account] = AzureClientInstance.getAllAccounts();

  try {
    const res = await AzureClientInstance.acquireTokenSilent({
      scopes: ["User.Read"],
      account: account,
      // forceRefresh: true,
    });
    return res.idToken;
  } catch (error) {
    console.log('teste maluco')
    const res = await AzureClientInstance.acquireTokenPopup({
      prompt: "select_account",
      scopes: ["User.Read"],
      account: account,
    });
    return res.idToken;
  }
};
