import FormCV from "components/CV/Form";
import { useLocation } from "react-router-dom";
import { DataListMapper } from "services/cv/mappers/dataListMapper";

type Props = {};

const EditCV = (props: Props) => {
  const { state } = useLocation();
  const cvSelected = DataListMapper.oneToDomain(state);
  return (
    // Inserir os passos de cadastro/edição individualmente para que o cvSelected seja passado para eles sem a mediação do FormCV
    <FormCV cvSelected={cvSelected} />
  )
};

export default EditCV;