import {IPersonalInformation, IPersonalInformationFront} from "../interfaces";

interface IPersonalInformationMapper {
  toDomain: (data: IPersonalInformation) => IPersonalInformationFront;
  toModel: (data: IPersonalInformationFront) => IPersonalInformation;
}

const convertModelToDomain = (
  data: IPersonalInformation
): IPersonalInformationFront => {
  return {
    email: data.email,
    language: data.linguagem,
    name: data.nome,
    role: data.cargo,
    summary: data.sumario,
    step: data.step,
  };
};

const convertDomainToModel = (
  data: IPersonalInformationFront
): IPersonalInformation => {
  return {
    email: data.email,
    linguagem: data.language,
    nome: data.name,
    cargo: data.role,
    sumario: data.summary,
    step: data.step,
  };
};

export const PersonalInformationMapper: IPersonalInformationMapper = {
  toDomain: convertModelToDomain,
  toModel: convertDomainToModel,
};
