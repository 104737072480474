import { PublicClientApplication, LogLevel } from "@azure/msal-browser";
import { Config } from "./config";

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: Config.appId,
    redirectUri: Config.redirectUri,
    authority: Config.authority,
    postLogoutRedirectUri: Config.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
});

export default publicClientApplication;
