import { Box, Button, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Logo from "../../layouts/logos/d+b branco - horizontal.png";

type Props = {};

const SignIn = (props: Props) => {
  const theme = useTheme();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = () => {
    instance
      .loginPopup({ prompt: "select_account", scopes: ["User.Read"] })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        background: theme.palette.dbDarkBlue.main,
        height: "100vh",
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "5%",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img src={Logo} alt="DBServer" style={{ maxWidth: "24rem" }} />
        </Box>

        <Typography variant="h4" color={theme.palette.dbDarkBlue.contrastText}>
          CV - DB
        </Typography>
        <Button
          variant="outlined"
          color="dbPink"
          sx={{ marginTop: "4rem" }}
          size="large"
          onClick={handleLogin}
        >
          Entrar
        </Button>
      </Container>
    </Box>
  );
};

export default SignIn;
