import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { refreshToken } from "configuration/azureAD/refreshToken";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = await refreshToken();

  config.headers = {
    Authorization: "Bearer " + token,
    ...config.headers,
  };
  return config;
});

export {
  instance as Axios,
  type AxiosResponse,
  type AxiosRequestConfig,
  AxiosError,
};
