import { Typography, Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  maxSteps: number;
  currentStep: number;
};
const Header = ({ maxSteps, currentStep }: Props) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Typography
          textTransform="uppercase"
          variant="h6"
          mx="auto"
          color="darkblue"
          fontSize={20}
          fontWeight="bold"
          sx={{ mt: "1rem" }}
        >
          {t("cvForm.titles.form")}
        </Typography>
      </Box>
      <Box>
        <Typography
          textTransform="uppercase"
          variant="h6"
          mx="auto"
          color="darkblue"
          fontSize={20}
          fontWeight="bold"
          sx={{ mt: "1rem" }}
        >
          {t("step")} {currentStep} {t("of")} {maxSteps}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
