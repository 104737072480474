import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from "configuration/http/httpClient";
import { Axios, AxiosError, AxiosResponse } from "./config";

export class AxiosClient implements HttpClient {
  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse;
    try {
      axiosResponse = await Axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: data.headers,
        withCredentials: true,
        params: data.params,
      });
    } catch (error) {
      const e: AxiosError = error as AxiosError;
      axiosResponse = e.response as AxiosResponse;
    }
    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    };
  }
}
