import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  TextField,
} from "@mui/material";
import { IExperiencesFront } from "services/cv/interfaces";
import { ExperiencesMapper } from "services/cv/mappers";
import { FactoryExperienciesPostCV } from "services/cv/experiences/remoteExperiencesPostCV";

import {
  useAppDispatch,
  useAppSelector,
} from "configuration/reduxToolkit/hooks";

import {
  selectCountErrors,
  selectLanguage,
  setAlert,
  setLoading,
} from "reduxFeatures/app/appSlice";
import { useCallback, useEffect, useState } from "react";

import { GridColDef } from "@mui/x-data-grid";
import {
  formatDateStringENFromDateStringBR,
  formatStringDateEnFromDateBR,
  getConfigDateLocale,
  LanguageTextByAcronym,
} from "shared/utils";
import { FactoryDeleteExperienciesCV } from "services/cv/experiences/remoteExperienciesDeleteCV";
import { FactoryExperienciesPutCV } from "services/cv/experiences/remoteExperiencesPutCV";
import { useTranslation } from "react-i18next";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isValid } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { IBaseCombosFront } from "services/combos/interfaces";
import ConditionalStepForm from "../ConditionalStepForm";
import DialogConfirmOtherInformation from "../OtherInformations/DialogConfirmOtherInformation";
import TableOtherInformation from "../OtherInformations/TableOtherInformation/indx";
import  { IForm } from "../../../../services/cv/interfaces";
import {
  dateEndIsSmallerThanDateInit,
  validateStatusToAllowdateEnd,
} from "shared/utils/validationUtils";

type Props<T> = {
  stepForm: number;
  cvId: string;
  listExperiences: T[];
  handleNextStage: any;
};

const NUMBER_STEP = 3;

const MESSAGE_ERROR =
  "Ocorreu um erro inesperado ao preencher os dados na tela.";

const translaterFields: ITranslaterDataInputsOtherInformation = {
  name: { keyTranslater: "company" },
  dateInit: { keyTranslater: "dateInit" },
  dateEnd: { keyTranslater: "dateEnd" },
  status: { keyTranslater: "current" },
  activities: { keyTranslater: "activities" },
  buttonAdd: { keyTranslater: "experience" },
  buttonEdit: { keyTranslater: "experience" },
  other: { keyTranslater: "role" },
};

interface ITranslaterDataInputsOtherInformation {
  name: IKeysTranslater;
  dateInit: IKeysTranslater;
  dateEnd: IKeysTranslater;
  status?: IKeysTranslater;
  activities: IKeysTranslater;
  buttonAdd: IKeysTranslater;
  buttonEdit?: IKeysTranslater;
  other: IKeysTranslater;
}

interface IKeysTranslater {
  keyTranslater: string;
}




const SITUATION_INIT: IBaseCombosFront = {
  description: "",
  id: "",
  language: "",
};

const Experiencies = ({
  stepForm,
  cvId,
  listExperiences,
  handleNextStage,
}: Props <IExperiencesFront>) => {
  const countErrors = useAppSelector(selectCountErrors);
  const langAcronym = useAppSelector(selectLanguage);
  const languageName = LanguageTextByAcronym[langAcronym];

  const [shrinkValueName, setShrinkValueName] = useState<boolean>(false);
  const [shrinkValueExperience, setShrinkValueExperience] = useState<boolean>(false);
  const [shrinkValueActivities, setShrinkValueActivities] = useState<boolean>(false);
 
  const [isRequired, setIsRequired] = useState<boolean>(true);
  const { t } = useTranslation();
  const [listSituation, setListSituation] = useState<IBaseCombosFront[]>([]);
  const [listExperiencies, setListExperiencies] = useState<IExperiencesFront[]>(
    []
  );
  const [selectedExperienceEdit, setSelectedExperienceEdit] = useState<
    IForm | undefined
  >();
  const [situation, setSituation] = useState<IBaseCombosFront>(SITUATION_INIT);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("cvForm.labels.name"),
      flex: 1,
      sortable: false,
    },
    {
      field: "other",
      headerName: t("cvForm.labels.role"),
      flex: 1,
      sortable: false,
    },
    {
      field: "dateInit",
      headerName: t("cvForm.labels.dateInit"),
      flex: 1,
      sortable: false,
      renderCell(params) {
        return formatDateStringENFromDateStringBR(params.value);
      },
    },
    {
      field: "dateEnd",
      headerName: t("cvForm.labels.dateEnd"),
      flex: 1,
      sortable: false,
      renderCell(params) {
        return formatDateStringENFromDateStringBR(params.value);
      },
    },
    {
      field: "status",
      headerName: `${t("cvForm.labels.experience")} ${t(
        "cvForm.labels.current"
      )}`,
      flex: 1,
      sortable: false,
      renderCell(params) {
        return params.value ? "Sim" : "Não";
      },
    },
  ];

  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedExperienceDelete, setSelectedExperienceDelete] =
    useState<IExperiencesFront>();
  const [experiences, setExperiences] =
    useState<IExperiencesFront[]>(listExperiences);

  useEffect(() => {
    if (stepForm !== NUMBER_STEP) {
      setSelectedExperienceEdit(undefined);
      setSelectedExperienceDelete(undefined);
    }
  }, [stepForm]);

  const checkedStatusAdd = (data: any) => {
    const [checked] = experiences.filter((ex) => ex.status);
    if (checked && data.status) {
      const remove = experiences.filter((item) => item.id !== checked.id);
      setExperiences([...remove, data, { ...checked, status: false }]);
    } else {
      setExperiences([...experiences, data]);
    }
  };
  
  const checksIfOneExperienceItsActual = (value: boolean | undefined ) => {
    const actual = experiences.some((ex)=> ex.status === true);
    if(actual && value ){
      dispatch(
        setAlert({
          open: true,
          message: "Deve existir apenas uma Experiência 'atual' adicionada.",
          type: "error",
        }))
    }
    if(actual && !isRequired){
      return "Remova a experiência atual que já existe"
    }
  }

  const checkedStatusEdit = (data: any) => {
    const [checked] = experiences.filter(
      (ex) => ex.status && ex.id !== data.id
    );
    if (checked && data.status) {
      const experiencesNotData = experiences.filter(
        (item) => item.id !== checked.id && item.id !== data.id
      );
      setExperiences([
        ...experiencesNotData,
        data,
        { ...checked, status: false },
      ]);
    } else {
      const experiencesNotData = experiences.filter((ex) => ex.id !== data.id);
      setExperiences([...experiencesNotData, data]);
    }
  };

  const transformFormToExperience = (data: IForm): IExperiencesFront => {
    const {
      activities,
      step,
      dateEnd,
      dateInit,
      id,
      name,
      other,
      status = Boolean(data.status),
    } = data;
    return { activities, dateEnd, dateInit, id, name, other, status, step };
  };

  const handleOnSubmit = async (data: IForm) => {
    data.step = 2;
    const model = ExperiencesMapper.toModel(transformFormToExperience(data));
    const experiencesNotData = experiences.filter((ed) => ed.id !== model.id);
    dispatch(setLoading(true));
    try {
      if (selectedExperienceEdit) {
        const resp = await FactoryExperienciesPutCV.put(model.id, model);
        const domain = ExperiencesMapper.toDomain(resp) as any;
        setExperiences([...experiencesNotData, domain]);
        checkedStatusEdit(domain);
        setSelectedExperienceEdit(undefined);
      } else {
        const resp = await FactoryExperienciesPostCV.post(cvId, model);
        const domain = ExperiencesMapper.toDomain(resp) as any;
        setExperiences([...experiencesNotData, domain]);
        checkedStatusAdd(domain);
      }
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          open: true,
          message: "Experiencia adicionada com sucesso.",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setAlert({
          open: true,
          message: error.message,
          type: "error",
        })
      );
      dispatch(setLoading(false));
    }
  };

  const handleDelete = async (id: string) => {
    const [selected] = experiences.filter((item) => item.id === id);
    setSelectedExperienceDelete(selected);
    setOpenDialog(true);
  };
  const handleEdit = async (id: string) => {
    const [edit] = experiences.filter((item) => item.id === id);

    setSelectedExperienceEdit(edit);
  };
  const handleCloseModel = async (value: boolean) => {
    if (!value) {
      setOpenDialog(false);
      setSelectedExperienceDelete(undefined);
    } else {
      dispatch(setLoading(true));
      try {
        if (selectedExperienceDelete) {
          const model = ExperiencesMapper.toModel(selectedExperienceDelete);
          await FactoryDeleteExperienciesCV.delete(cvId, model);
          const remove = experiences.filter(
            (item) => item.id !== selectedExperienceDelete.id
          );
          dispatch(setLoading(false));
          setExperiences(remove);
          setOpenDialog(false);
          setSelectedExperienceEdit(undefined);
          setSelectedExperienceDelete(undefined);
          dispatch(
            setAlert({
              open: true,
              message: "Experiencia removida com sucesso.",
              type: "success",
            })
          );
        }
      } catch (error: any) {
        dispatch(setLoading(false));
        setOpenDialog(false);
        setSelectedExperienceEdit(undefined);
        setSelectedExperienceDelete(undefined);
        dispatch(
          setAlert({
            open: true,
            message: error.message,
            type: "error",
          })
        );
      }
    }
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    trigger,
    getValues,

    formState: { errors, isSubmitSuccessful, isDirty, isValid },
  } = useForm<IForm>({
    mode: "onChange",
  });

  const mountLabelTranslater = (field: string): string => {
    const key =
      translaterFields[field as keyof ITranslaterDataInputsOtherInformation];
    return key ? t(`cvForm.labels.${key.keyTranslater}`) : "";
  };

  useEffect(() => {
    if (selectedExperienceEdit) {
      reset({ ...selectedExperienceEdit });
      if (setSituation) {
        const situation = listSituation.filter(
          (st) => st.id === selectedExperienceEdit.situation
        )[0];
        setSituation(situation as IBaseCombosFront);
      }
    }
    setShrinkValueName(true);
    setShrinkValueExperience(true);
    setShrinkValueActivities(true);
  }, [listSituation, reset, selectedExperienceEdit, setSituation]);

  const handleCancel = useCallback(() => {
    reset({
      ...{
        activities: "",
        dateEnd: undefined,
        dateInit: undefined,
        id: "",
        name: "",
        other: "",
        situation: "",
        status: false,
      },
    });
    setIsRequired(true);
    setSelectedExperienceEdit(undefined);
  }, [reset, setSelectedExperienceEdit]);
  
  useEffect(() => {
    if (stepForm ===  3 ) {
      if (experiences.length > 0) {
        handleNextStage(true, stepForm);
      } else {
        handleNextStage(false, stepForm);
      }
  }}, [stepForm, experiences]);
  

  return (
    <ConditionalStepForm
      show={stepForm === NUMBER_STEP}
      showError={countErrors > 0}
      messageError={MESSAGE_ERROR}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getConfigDateLocale(langAcronym)}
      >
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <Grid container sx={{ mt: "1rem", mb: "2rem" }} spacing={4}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  type="text"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Campo Obrigatório",
                    },
                    minLength: {
                      value: 3,
                      message: "O campo deve ter no mínimo 3 caracteres.",
                    },
                    maxLength: {
                      value: 100,
                      message: "O campo deve ter no máximo 100 caracteres.",
                    },
                  })}
                  InputLabelProps={{
                    shrink: shrinkValueName,
                  }}
                  onFocus={(_) => setShrinkValueName(true)}
                  onBlur={(_) =>
                    setShrinkValueName(_.target.value.length ? true : false)
                  }
                  label={mountLabelTranslater("name")}
                  sx={{ maxWidth: 1, width: "100%" }}
                  size="small"
                  error={!!errors.name}
                  helperText={
                    errors.name ? (
                      <span data-cy="erro-name">
                        {errors.name?.message?.toString()}
                      </span>
                    ) : null
                  }
                  data-cy="name"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="text"
                  {...register("other", {
                    required: {
                      value: true,
                      message: "Campo Obrigatório",
                    },
                    minLength: {
                      value: 3,
                      message: "O campo deve ter no mínimo 3 caracteres.",
                    },
                    maxLength: {
                      value: 100,
                      message: "O campo deve ter no máximo 100 caracteres.",
                    },
                  })}
                  InputLabelProps={{
                    shrink: shrinkValueExperience,
                  }}
                  onFocus={(_) => setShrinkValueExperience(true)}
                  onBlur={(_) =>
                    setShrinkValueExperience(_.target.value.length ? true : false)
                  }
                  label={mountLabelTranslater("other")}
                  sx={{ maxWidth: 1, width: "100%" }}
                  size="small"
                  error={!!errors.other}
                  helperText={
                    errors.other ? (
                      <span data-cy="erro-other">
                        {errors.other?.message?.toString()}
                      </span>
                    ) : null
                  }
                  data-cy="other"
                />
              </Grid>
              <Grid container item spacing={2} xs={4}>
                <Grid item xs={6}>
                  <Controller
                    name="dateInit"
                    rules={{
                      required: { value: true, message: "Campo Obrigatório" },
                    }}
                    control={control}
                    render={({ field, ...props }) => {
                      return (
                        <DatePicker
                          {...props}
                          label={mountLabelTranslater("dateInit")}
                          renderInput={(params: any) => (
                            <TextField
                              size="small"
                              data-cy="dataInicial"
                              {...params}
                              error={!!errors.dateInit}
                              helperText={
                                errors.dateInit ? (
                                  <span data-cy="erro-data-inicial">
                                    {errors.dateInit?.message?.toString()}
                                  </span>
                                ) : null
                              }
                            />
                          )}
                          value={
                            field.value
                              ? formatStringDateEnFromDateBR(field.value)
                              : null
                          }
                          onChange={(e) => {
                            field.onChange(e);
                            trigger("dateEnd").then((_) => _);
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    rules={{
                      validate: {
                        validateIfDateEndIsSmallerThanDateInit: (
                          value: string
                        ) => {
                          let dateInitValue: string = getValues()["dateInit"];
                          return dateEndIsSmallerThanDateInit(
                            value,
                            dateInitValue
                          );
                        },
                        validateStatusToAllowateEnd: (value: string) => {
                          let isChecked: boolean | undefined =
                            getValues()["status"];
                          return validateStatusToAllowdateEnd(value, isChecked);
                        },
                      },
                    }}
                    name="dateEnd"
                    control={control}
                    render={({ field, ...props }) => {
                      return (
                        <DatePicker
                          {...props}
                          label={mountLabelTranslater("dateEnd")}
                          renderInput={(params: any) => (
                            <TextField
                              size="small"
                              data-cy="dataFinal"
                              {...params}
                              error={!!errors.dateEnd}
                              helperText={
                                errors.dateEnd ? (
                                  <span data-cy="erro-data-final">
                                    {errors.dateEnd?.message?.toString()}
                                  </span>
                                ) : null
                              }
                            />
                          )}
                          value={
                            field.value
                              ? formatStringDateEnFromDateBR(field.value)
                              : null
                          }
                          onChange={(e) => field.onChange(e)}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={2} spacing={2} alignItems="flex-start">
                <Grid item xs={12}>
                  {translaterFields.status !== undefined ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        label={mountLabelTranslater("status")}
                        control={
                          <Controller
                            name="status"
                            control={control}
                            rules={{
                              validate: {
                                verifyIfHaveActualExperience(value){
                                  return checksIfOneExperienceItsActual(value);
                                }}
                              }
                            }
                            render={({ field: { value, ...field } }) => (
                              <Checkbox
                                color="dbDarkBlue"
                                {...field}
                                checked={value}
                                onClick={() => {
                                  setIsRequired(!!value);
                                  trigger("dateEnd").then((_) => _);
                                }}
                              />
                            )}
                          />
                        }
                      />
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  data-cy="atividades"
                  {...register("activities", {
                    required: {
                      value: true,
                      message: "Campo Obrigatório",
                    },
                    minLength: {
                      value: 15,
                      message: "O campo deve ter no mínimo 15 caracteres.",
                    },
                    maxLength: {
                      value: 2000,
                      message: "O campo deve ter no máximo 2000 caracteres.",
                    } 
                  })}
                  label={mountLabelTranslater("activities")}
                  InputLabelProps={{
                    shrink: shrinkValueActivities,
                  }}
                  onFocus={(_) => setShrinkValueActivities(true)}
                  onBlur={(_) =>
                    setShrinkValueActivities(_.target.value.length ? true : false)
                  }
                  multiline
                  minRows={5}
                  fullWidth
                  variant="outlined"
                  placeholder={t("cvForm.placeholder.text2000Caracters")}
                  size="small"
                  error={!!errors.activities}
                  helperText={
                    errors.activities ? (
                      <span data-cy="erro-atividades">
                        {errors.activities?.message?.toString()}
                      </span>
                    ) : null
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
                display="flex"
                columnGap={2}
              >
                <Button
                  variant="contained"
                  type="button"
                  color="dbRed"
                  sx={{
                    display: Boolean(selectedExperienceEdit)
                      ? "initial"
                      : "none",
                  }}
                  onClick={handleCancel}
                  data-cy="botaoVoltar"
                >
                  {t(`cvForm.buttons.clear.reset`)}
                </Button>

                <Button
                  data-testId="botaoSubmit"
                  variant="contained"
                  type="submit"
                  color="dbDarkBlue"
                  disabled={!isValid}
                  data-cy="botaoSubmit"
                >
                  {t(
                    selectedExperienceEdit
                      ? `cvForm.buttons.edit.${translaterFields?.buttonEdit?.keyTranslater}`
                      : `cvForm.buttons.add.${translaterFields.buttonAdd.keyTranslater}`
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
      <DialogConfirmOtherInformation
        handleOnclose={handleCloseModel}
        open={openDialog}
        question="Você deseja excluir essa experiência?"
        title="Exclusão de Experiência"
      />

      <Divider />
      <ConditionalStepForm show={experiences.length > 0}>
        <TableOtherInformation
          columns={columns}
          rows={experiences}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          pageSize={5}
        />
      </ConditionalStepForm>
    </ConditionalStepForm>
  );
};

export default Experiencies;
