import { AxiosClient } from "configuration/axios/client";
import {
  HttpClient,
  HttpMessageError,
  HttpStatusCode,
} from "configuration/http/httpClient";
import { IDeleteExperiences, IExperiences } from "../interfaces";

export class RemoteDeleteExperienciesCV implements IDeleteExperiences {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<{}>
  ) {}

  async delete(id: string, data: IExperiences): Promise<{}> {
    const response = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "delete",
      body: data,
    });

    const remoteData = response.body || {};
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return remoteData;
      case HttpStatusCode.created:
        return remoteData;
      case HttpStatusCode.noContent:
        return {};
      case HttpStatusCode.badRequest:
        throw new Error(HttpMessageError[400]);
      default:
        throw new Error(HttpMessageError[500]);
    }
  }
}

const FactoryDeleteExperienciesCV: IDeleteExperiences =
  new RemoteDeleteExperienciesCV("/experiencias/remove", new AxiosClient());

export { FactoryDeleteExperienciesCV };
