import { IListCV, ITableList } from "./interfaces";
import { formatDateStringENFromDateStringBR } from "shared/utils";

export const tableListMapper = {
  toTableList: (data: IListCV): ITableList[] => {
    const { content } = data;
    const tableList: ITableList[] = content.map((item) => {
      const currentCompany = item.experiencias
        ? item.experiencias.filter((company) => company.status)[0]
        : null;
      return {
        id: item.id,
        name: item.dadosPessoais.nome,
        email: item.dadosPessoais.email,
        role: item.dadosPessoais.cargo,
        company: currentCompany ? currentCompany.nomeEmpresa : "",
        dateInit: currentCompany
          ? formatDateStringENFromDateStringBR(currentCompany.dataInicio)
          : "",
      };
    });
    return tableList;
  },
};
