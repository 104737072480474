import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";

import { IMenu } from "./interfaces";
import Logo from "../logos/logo rosa.png";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  menu: IMenu[];
  openDrawer: boolean;
  toggleDrawer: () => void;
};

const Drawer = ({ openDrawer, toggleDrawer, menu }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Box component="nav">
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <Box
          sx={[
            { width: 250, height: "100%" },
            (theme) => ({
              background: theme.palette.dbDarkBlue.main,
              color: theme.palette.dbDarkBlue.contrastText,
            }),
          ]}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={Logo} alt="Logo DBServer" width="100" height="100" />
          </Box>
          <Divider />
          <List>
            {menu.map((item, index) => (
              <ListItem key={`${item.name}-${index}`} disablePadding>
                <ListItemButton
                  onClick={() => navigate(item.path)}
                  selected={pathname === item.path}
                  sx={(theme) => ({
                    "&.Mui-selected": {
                      background: theme.palette.dbPink.main,
                      color: theme.palette.dbPink.contrastText,
                    },
                    "&:hover": {
                      background: theme.palette.dbDarkBlue.main,
                      color: theme.palette.dbPink.main,
                    },
                  })}
                >
                  <ListItemIcon>
                    {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default Drawer;
