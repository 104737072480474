import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useAppSelector } from "configuration/reduxToolkit/hooks";
import React from "react";
import { selectLanguage } from "reduxFeatures/app/appSlice";
import { IBaseCombosFront } from "services/combos/interfaces";
import { LanguageTextByAcronym } from "shared/utils";

type Props = {
  rows: IBaseCombosFront[];
  name: string;
  label: string;
  handleSelect: (event: SelectChangeEvent) => void;
  value: string | undefined;
  disabled?: boolean;
  defaultValue?: string | undefined;
  optionClear?: string;
  keyNameDescription: keyof IBaseCombosFront;
  keyNameValueSelected: keyof IBaseCombosFront;
};

const SelectByObject = ({
  name,
  label,
  handleSelect,
  value,
  rows,
  defaultValue,
  disabled,
  optionClear,
  keyNameDescription,
  keyNameValueSelected,
}: Props) => {
  const language = useAppSelector(selectLanguage);
  const descriptionLanguage = LanguageTextByAcronym[language];

  if (rows.length && optionClear) {
    const optionValue: IBaseCombosFront = {
      id: optionClear,
      description: optionClear,
      language: descriptionLanguage.toUpperCase(),
    };
    rows.push(optionValue);
  }

  return (
    <FormControl fullWidth size="small">
      <InputLabel id={`select-string-${label}`}>{label}</InputLabel>
      {rows.length ? (
        <Select
          id={`select-string-${label}`}
          name={name}
          onChange={handleSelect}
          label={label}
          value={value}
          sx={{ maxWidth: 1, width: "100%" }}
          MenuProps={{ disableScrollLock: true }}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {rows.map((item, index) => (
            <MenuItem
              key={index}
              data-cy={item.description}
              value={
                optionClear && item[keyNameDescription] === optionClear
                  ? ""
                  : item[keyNameValueSelected]
              }
            >
              {item[keyNameDescription]}
            </MenuItem>
          ))}
        </Select>
      ) : null}
    </FormControl>
  );
};

export default SelectByObject;
