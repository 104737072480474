import { AxiosClient } from "configuration/axios/client";
import {
  HttpClient,
  HttpMessageError,
  HttpStatusCode,
} from "configuration/http/httpClient";
import { IPostExperienciesCV, IExperiences } from "../interfaces";

export class RemoteExperienciesPostCV implements IPostExperienciesCV {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IExperiences>
  ) {}

  async post(id: string, data: IExperiences): Promise<IExperiences> {
    const response = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "post",
      body: data,
    });

    const remoteData = response.body || ({} as IExperiences);
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return remoteData;
      case HttpStatusCode.created:
        return remoteData;
      case HttpStatusCode.noContent:
        return {} as IExperiences;
      case HttpStatusCode.badRequest:
        throw new Error(HttpMessageError[400]);
      default:
        throw new Error(HttpMessageError[500]);
    }
  }
}

const FactoryExperienciesPostCV: IPostExperienciesCV =
  new RemoteExperienciesPostCV("/experiencias/add", new AxiosClient());

export { FactoryExperienciesPostCV };
