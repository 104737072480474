import { Backdrop, CircularProgress, Typography } from "@mui/material";

type Props = {
  isLoading: boolean;
};

const Loading = ({ isLoading }: Props) => {
  return isLoading ? (
    <Backdrop
      sx={{
        zIndex: "9999 !important",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      open={true}
    >
      <Typography variant="h6" gutterBottom={true}>
        Carregando...
      </Typography>
      <CircularProgress color="primary" />
    </Backdrop>
  ) : null;
};

export default Loading;
