import { IKnowledge, IKnowledgeFront } from "../interfaces";

interface IKnowledgeMapper {
  toDomain: (data: IKnowledge) => IKnowledgeFront;
  toModel: (data: IKnowledgeFront) => IKnowledge;
  arrayToDomain: (data: IKnowledge[]) => IKnowledgeFront[];
  arrayToModel: (data: IKnowledgeFront[]) => IKnowledge[];
}

const convertModelToDomain = (data: IKnowledge): IKnowledgeFront => {
  return {
    id: data.id,
    active: data.ativo,
    technology: {
      description: data.tecnologia.descricao,
      id: data.tecnologia.id,
      language: data.tecnologia.linguagem,
    },
    profile: {
      description: data.perfil.descricao,
      id: data.perfil.id,
      language: data.perfil.linguagem,
    },
  };
};

const convertDomainToModel = (data: IKnowledgeFront): IKnowledge => {
  return {
    id: data.id,
    ativo: data.active,
    tecnologia: {
      descricao: data.technology.description,
      id: data.technology.id,
      linguagem: data.technology.language,
    },
    perfil: {
      descricao: data.profile.description,
      id: data.profile.id,
      linguagem: data.profile.language,
    },
  };
};

const arrayModelToDomain = (listaConhecimento: IKnowledge[]): IKnowledgeFront[] => {
  const knowledgeList = listaConhecimento.map(conhecimento =>
    convertModelToDomain(conhecimento));
    return knowledgeList;
}

const arrayDomainToModel = (knowledgeList: IKnowledgeFront[]): IKnowledge[] => {
  const listaConhecimentos = knowledgeList.map(knowledge => 
    convertDomainToModel(knowledge));
    return listaConhecimentos;
}

export const KnowledgeMapper: IKnowledgeMapper = {
  toDomain: convertModelToDomain,
  toModel: convertDomainToModel,
  arrayToDomain: arrayModelToDomain,
  arrayToModel: arrayDomainToModel,
};
