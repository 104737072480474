import React from "react";

import { FactoryGetListCV } from "services/cv/remoteGetCV";
import ListCV from "components/CV/List";

type Props = {};

const ListCVPage = (props: Props) => {
  return <ListCV getListCV={FactoryGetListCV} />;
};

export default ListCVPage;
