import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ptBr from "./locales/pt-BR.json";
import enUs from "./locales/en-US.json";

const resources = {
  "pt-BR": ptBr,
  "en-US": enUs,
};

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  defaultNS: "common",
  fallbackLng: "pt-BR",
});

export default i18n;