import React, {useEffect, useState} from "react";
import {Box, Divider, Grid, TextField} from "@mui/material";

import {useAppDispatch, useAppSelector,} from "../../../../configuration/reduxToolkit/hooks";
import {
  decrementCountErros,
  incrementCountErrors,
  selectCountErrors,
  setAlert,
  setLoading,
} from "../../../../reduxFeatures/app/appSlice";

import {FactoryRemoteLanguagesCV} from "services/combos/remoteLanguagesCV";

import {MESSAGE_INVALID_EMAIL, MESSAGE_REQUIRED_FIELD, REGEX_EMAIL,} from "shared/utils";
import LoadingSkeleton from "../../../LoadingSkeleton";
import {Control, FieldErrors, UseFormHandleSubmit, UseFormRegister,} from "react-hook-form";
import SelectStringControl from "components/SelectStringControl";
import {useTranslation} from "react-i18next";
import ConditionalStepForm from "../ConditionalStepForm";
import {IPersonalInformationFront} from "services/cv/interfaces";

type Props = {
  register: UseFormRegister<IPersonalInformationFront>;
  errors: FieldErrors<IPersonalInformationFront>;
  control?: Control<IPersonalInformationFront>;
  showStep: boolean;
  handleSubmit: UseFormHandleSubmit<IPersonalInformationFront>;
  handOnClick: (data: IPersonalInformationFront) => void;
};

const MESSAGE_ERROR =
  "Ocorreu um erro inesperado ao preencher os dados na tela.";

const PersonalInformation = ({
  register,
  errors,
  control,
  showStep,
  handleSubmit,
  handOnClick,
}: Props) => {
  const dispatch = useAppDispatch();
  const countErrors = useAppSelector(selectCountErrors);

  const { t } = useTranslation();

  const [languagesCV, setLanguagesCV] = useState<string[]>([]);

  useEffect(() => {
    dispatch(setLoading(true));
    FactoryRemoteLanguagesCV.get()
      .then((r) => {
        setLanguagesCV(r);
        dispatch(decrementCountErros());
        dispatch(setLoading(false));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        dispatch(incrementCountErrors());
        dispatch(setAlert({ type: "error", open: true, message: e.message }));
      });
  }, [dispatch]);

  return (
    <ConditionalStepForm
      show={showStep}
      showError={countErrors > 0}
      messageError={MESSAGE_ERROR}
    >
      <Box component="form">
        <Divider />
        <LoadingSkeleton quantiyItens={10}>
          <Grid container spacing={4} sx={{ mt: "0.5rem", mb: "2rem" }}>
            <Grid container item xs={12} columnSpacing={4}>
              <Grid container item xs={6} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: MESSAGE_REQUIRED_FIELD,
                      },
                      pattern: {
                        value: new RegExp(REGEX_EMAIL),
                        message: MESSAGE_INVALID_EMAIL,
                      }
                    
                    })}
                    label={t("cvForm.labels.email")}
                    sx={{ maxWidth: 1, width: "100%" }}
                    size="small"
                    error={!!errors.email}
                    helperText={
                      errors.email ? (
                        <span data-cy="erro-campo-email">{errors.email?.message?.toString()}</span>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register("name", {
                      required: {
                        value: true,
                        message: MESSAGE_REQUIRED_FIELD,
                      },
                      minLength: {
                        value: 3,
                        message: MESSAGE_REQUIRED_FIELD,
                      },
                    })}
                    label={t("cvForm.labels.name")}
                    sx={{ maxWidth: 1, width: "100%" }}
                    size="small"
                    error={!!errors.name}
                    helperText={
                      errors.name ? (
                          <span data-cy="erro-campo-nome">{errors.name?.message?.toString()}</span>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register("role", {
                      required: {
                        value: true,
                        message: MESSAGE_REQUIRED_FIELD,
                      },
                      minLength: {
                        value: 3,
                        message: MESSAGE_REQUIRED_FIELD,
                      },
                    })}
                    label={t("cvForm.labels.role")}
                    sx={{ maxWidth: 1, width: "100%" }}
                    size="small"
                    error={!!errors.role}
                    helperText={
                      errors.role ? (
                        <span data-cy="erro-campo-cargo">{errors.role?.message?.toString() }</span>
                      ) : null
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {languagesCV.length ? (
                  <SelectStringControl
                    label={t("cvForm.labels.language")}
                    name="language"
                    rows={languagesCV}
                    control={control}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Divider />

          <Grid container sx={{ mt: "2rem", mb: "2rem" }}>
            <Grid item xs={12}>
              <TextField
                {...register("summary")}
                label={t("cvForm.labels.summary")}
                multiline
                minRows={10}
                fullWidth
                variant="outlined"
                placeholder={t("cvForm.placeholder.text2000Caracters")}
                size="small"
              />
            </Grid>
          </Grid>
        </LoadingSkeleton>
      </Box>
    </ConditionalStepForm>
  );
};

export default PersonalInformation;