export const Config = {
  appId: process.env.REACT_APP_AZURE_APP_ID || "",
  redirectUri: process.env.REACT_APP_AZURE_APP_CALLBACK_REDIRECT || "",
  directoryId: process.env.REACT_APP_AZURE_APP_DIRECTORY_ID || "",
  postLogoutRedirectUri:
    process.env.REACT_APP_AZURE_APP_CALLBACK_POST_LOGOUT || "",
  APPINSIGHTS_KEY:
    process.env.REACT_APP_AZURE_APP_APPINSIGHTS_KEY ||
    "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx",
  APP_NAME: process.env.REACT_APP_AZURE_APP_NAME || "",
  scopes: ["user.read"],
  authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_APP_TENANT_ID}/`,
  clientSecret: process.env.REACT_APP_AZURE_APP_CLIENT_SECRET || "",
};
