import { AxiosClient } from "configuration/axios/client";
import { HttpClient, HttpStatusCode, HttpMessageError } from "configuration/http/httpClient";
import { IProficiencies } from "../interfaces";

const httpClient: HttpClient = new AxiosClient();
const url: string = "/proficiencias/add";

export const PostProficiencies = () => ({
    saveProficiencies: async (idCV: string, proficiencies: IProficiencies) => {
        const response = await httpClient.request({
            url: `${url}/${idCV}`,
            method: "post",
            body: proficiencies,
        });

        const remoteData = response.body
        // || step;
        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return remoteData;
            case HttpStatusCode.badRequest:
                throw new Error(HttpMessageError[400]);
            default:
                throw new Error(HttpMessageError[500]);
        }
    }
})