import ptBr from "../i18N/locales/pt-BR.json";
import enUs from "../i18N/locales/en-US.json";

const resources: { [key: string]: any } = {
  "pt-BR": ptBr,
  "en-US": enUs,
};

const resolve = (path: string, obj: any) => {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj);
};

export const translaterTexCVFormtByLanguage = (
  language: string,
  text: string
) => {
  const { common } = resources[language];
  return resolve(text, common);
};
