import { AxiosClient } from "configuration/axios/client";
import {
  HttpClient,
  HttpMessageError,
  HttpStatusCode,
} from "configuration/http/httpClient";
import { IPostCertificationsCV, ICertifications } from "../interfaces";

export class RemoteCertificationsPostCV implements IPostCertificationsCV {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<ICertifications>
  ) {}

  async post(id: string, data: ICertifications): Promise<ICertifications> {
    const response = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "post",
      body: data,
    });

    const remoteData = response.body || ({} as ICertifications);
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return remoteData;
      case HttpStatusCode.created:
        return remoteData;
      case HttpStatusCode.noContent:
        return {} as ICertifications;
      case HttpStatusCode.badRequest:
        throw new Error(HttpMessageError[400]);
      default:
        throw new Error(HttpMessageError[500]);
    }
  }
}

const FactoryCertificationsPostCV: IPostCertificationsCV =
  new RemoteCertificationsPostCV("/certificacoes/add", new AxiosClient());

export { FactoryCertificationsPostCV };
