import { AxiosClient } from "configuration/axios/client";
import {
  HttpClient,
  HttpMessageError,
  HttpStatusCode,
} from "configuration/http/httpClient";
import { IPostCV, IPersonalInformation, ListCVBase } from "../interfaces";

export class RemotePersonalInformationCV implements IPostCV {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<ListCVBase>
  ) {}

  async post(data?: IPersonalInformation): Promise<ListCVBase> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: data,
    });

    const remoteData = response.body || ({} as ListCVBase);
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return remoteData;
      case HttpStatusCode.created:
        return remoteData;
      case HttpStatusCode.noContent:
        return {} as ListCVBase;
      case HttpStatusCode.badRequest:
        throw new Error(HttpMessageError[400]);
      default:
        throw new Error(HttpMessageError[500]);
    }
  }
}

const FactoryPostPersonalInformationCV: IPostCV =
  new RemotePersonalInformationCV(
    "/curriculos/dados-pessoais",
    new AxiosClient()
  );

export { FactoryPostPersonalInformationCV };
