import { AxiosClient } from "configuration/axios/client";
import {
  HttpClient,
  HttpMessageError,
  HttpStatusCode,
} from "configuration/http/httpClient";
import { IBaseCombos, IGetListCombos } from "./interfaces";

class RemoteSituation implements IGetListCombos {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IBaseCombos[]>
  ) {}

  async get(): Promise<IBaseCombos[]> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "get",
    });
    const remoteData = response.body || [];
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return remoteData;
      case HttpStatusCode.noContent:
        return [];
      case HttpStatusCode.badRequest:
        throw new Error(HttpMessageError[400]);
      default:
        throw new Error(HttpMessageError[500]);
    }
  }
}

const FactoryRemoteSituation = new RemoteSituation(
  "/situacoes/all",
  new AxiosClient()
);

export { FactoryRemoteSituation };
