import { ILanguages, ILanguagesFront } from "../interfaces";

interface ILanguagesMapper {
  toDomain: (data: ILanguages) => ILanguagesFront;
  toModel: (data: ILanguagesFront) => ILanguages;
  arrayToDomain: (data: ILanguages[]) => ILanguagesFront[];
  arrayToModel: (data: ILanguagesFront[]) => ILanguages[];
}

const convertModelToDomain = (data: ILanguages): ILanguagesFront => {
  return {
    id: data.id,
    active: data.ativo,
    language: {
      description: data.lingua.descricao,
      id: data.lingua.id,
      language: data.lingua.linguagem,
    },
    level: {
      description: data.nivel.descricao,
      id: data.nivel.id,
      language: data.nivel.linguagem,
    },
  };
};

const convertDomainToModel = (data: ILanguagesFront): ILanguages => {
  return {
    id: data.id,
    ativo: data.active,
    lingua: {
      descricao: data.language.description,
      id: data.language.id,
      linguagem: data.language.language,
    },
    nivel: {
      descricao: data.level.description,
      id: data.level.id,
      linguagem: data.level.language,
    },
  };
};

const arrayModelToDomain = (listaConhecimentos: ILanguages[]): ILanguagesFront[] => {
  const languageList = listaConhecimentos.map(lingua => 
    convertModelToDomain(lingua));
    return languageList;
}

const arrayDomainToModel = (languagesList: ILanguagesFront[]): ILanguages[] => {
  const listaIdiomas = languagesList.map(language =>
    convertDomainToModel(language))
  return listaIdiomas;
}

export const LanguagesMapper: ILanguagesMapper = {
  toDomain: convertModelToDomain,
  toModel: convertDomainToModel,
  arrayToDomain: arrayModelToDomain,
  arrayToModel: arrayDomainToModel,
};
