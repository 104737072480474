import {Box, IconButton} from "@mui/material";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import LoadingSkeleton from "components/LoadingSkeleton";
import React from "react";
import {
  ICertificationsFront,
  IEducationsFront,
  IExperiencesFront,
} from "services/cv/interfaces";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from "react-i18next";

interface ITable extends IExperiencesFront, ICertificationsFront, IEducationsFront {

}

type Props = {
  rows: Partial<ITable>[];
  columns: GridColDef[];
  pageSize?: number;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
};

const TableOtherInformation = ({
                                 columns,
                                 rows,
                                 handleDelete,
                                 handleEdit,
                                 pageSize = 2,
                               }: Props) => {
  const {t} = useTranslation();

  const columnActions: GridColDef = {
    field: "actions",
    headerName: t("cvForm.labels.actions"),
    flex: 1,
    sortable: false,
    maxWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams) => (
      <Box sx={{display: "flex"}}>
        <IconButton
          color="dbDarkBlue"
          aria-label="Editar"
          data-cy="botaoEditar"
          onClick={() => handleEdit(params.row.id)}
        >
          <EditIcon/>
        </IconButton>
        <IconButton
          color="dbRed"
          data-cy="botaoExcluir"
          aria-label="Excluir"
          onClick={() => handleDelete(params.row.id)}
        >
          <DeleteIcon/>
        </IconButton>
      </Box>
    ),
  };
  return (
    <Box sx={{mt: "1rem", display: "flex", height: "100%"}}>
      <Box sx={{flexGrow: 1}}>
        <LoadingSkeleton quantiyItens={4}>
          <DataGrid
            autoHeight
            disableColumnMenu
            disableColumnFilter
            rows={rows}
            columns={columns.concat(columnActions)}
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
          />
        </LoadingSkeleton>
      </Box>
    </Box>
  );
};

export default TableOtherInformation;
