import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import Header from "./header";
import { IMenu } from "./interfaces";
import Drawer from "./drawer";

type Props = {};

const menu: IMenu[] = [
  {
    label: "CV's",
    name: "home",
    path: "/",
  },
  {
    label: "Novo CV",
    name: "novo",
    path: "/curriculo/create",
  },
 
];

const MainLayout: React.FC<Props> = (props: Props) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const toggleDrawer = () => setOpenDrawer(!openDrawer);

  return (
    <Box sx={{ display: "flex" }} position="static">
      <Header menu={menu} toggleDrawer={toggleDrawer} />
      <Drawer menu={menu} openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
      <Box component="main" sx={{ p: 3, flexGrow: 1, minHeight: "100vh" }}>
        <Toolbar />
        <Container maxWidth="xl">
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
