import { AxiosClient } from "configuration/axios/client";
import {
  HttpClient,
  HttpMessageError,
  HttpStatusCode,
} from "configuration/http/httpClient";
import { IPostEducationsCV, IEducations } from "../interfaces";

export class RemoteEducationsPostCV implements IPostEducationsCV {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IEducations>
  ) {}

  async post(id: string, data: IEducations): Promise<IEducations> {
    const response = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "post",
      body: data,
    });

    const remoteData = response.body || ({} as IEducations);
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return remoteData;
      case HttpStatusCode.created:
        return remoteData;
      case HttpStatusCode.noContent:
        return {} as IEducations;
      case HttpStatusCode.badRequest:
        throw new Error(HttpMessageError[400]);
      default:
        throw new Error(HttpMessageError[500]);
    }
  }
}

const FactoryEducationsPostCV: IPostEducationsCV = new RemoteEducationsPostCV(
  "/educacoes/add",
  new AxiosClient()
);

export { FactoryEducationsPostCV };
