import ErrorMessage from "components/ErrorMessage";
import React from "react";

type Props = {
  show: boolean;
  children: React.ReactNode;
  showError?: boolean;
  messageError?: string;
};

const ConditionalStepForm = ({
  show,
  children,
  showError,
  messageError,
}: Props) => {
  const render = () => {
    if (showError) {
      return <ErrorMessage data-cy="error" message={messageError} />;
    }
    return <section>{children}</section>;
  };

  return show ? render() : null;
};

export default ConditionalStepForm;
