import { formatDateStringFromDateStringEn } from "shared/utils";
import { ICertifications, ICertificationsFront } from "../interfaces";

interface ICertificationsMapper {
  toDomain: (data: ICertifications) => ICertificationsFront;
  toModel: (data: ICertificationsFront) => ICertifications;
}

const convertModelToDomain = (data: ICertifications): ICertificationsFront => {
  return {
    cvId: data.cvId,
    id: data.id,
    name: data.nomeInstituicao,
    other: data.curso,
    dateInit: data.dataInicio,
    dateEnd: data.dataTermino,
    activities: data.atividades,
    situation: {
      description: data.situacao.descricao,
      id: data.situacao.id,
      language: data.situacao.linguagem,
    },
    step: data.step,
  };
};

const convertDomainToModel = (data: ICertificationsFront): ICertifications => {
  return {
    cvId: data.cvId,
    id: data.id,
    nomeInstituicao: data.name,
    curso: data.other,
    dataInicio: formatDateStringFromDateStringEn(data.dateInit),
    dataTermino: formatDateStringFromDateStringEn(data.dateEnd),
    atividades: data.activities,
    situacao: {
      descricao: data.situation.description,
      id: data.situation.id,
      linguagem: data.situation.language,
    },
    step: data.step,
  };
};

export const CertificationMapper: ICertificationsMapper = {
  toDomain: convertModelToDomain,
  toModel: convertDomainToModel,
};
