import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {useAppDispatch, useAppSelector,} from "configuration/reduxToolkit/hooks";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  decrementCountErros,
  incrementCountErrors,
  selectCountErrors,
  selectLanguage,
  setAlert,
  setLoading,
} from "reduxFeatures/app/appSlice";
import {IBaseCombosFront} from "services/combos/interfaces";
import {IEducationsFront, IFormEducation} from "services/cv/interfaces";
import {EducationMapper} from "services/cv/mappers";
import {FactoryDeleteEducationsCV} from "services/cv/education/remoteEducationsDeleteCV";
import {FactoryEducationsPostCV} from "services/cv/education/remoteEducationsPostCV";
import {FactoryEducationsPutCV} from "services/cv/education/remoteEducationsPutCV";
import {
  formatDateStringENFromDateStringBR,
  formatStringDateEnFromDateBR,
  getConfigDateLocale,
  LanguageTextByAcronym
} from "shared/utils";
import ConditionalStepForm from "../ConditionalStepForm";
import DialogConfirmOtherInformation from "../OtherInformations/DialogConfirmOtherInformation";
import TableOtherInformation from "../OtherInformations/TableOtherInformation/indx";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {Controller, useForm} from "react-hook-form";
import {FactoryRemoteSituation} from "../../../../services/combos/remoteSituation";
import {ComboMapper} from "../../../../services/combos/comboMapper";
import {validateDateEnd, dateEndIsSmallerThanDateInit} from "../../../../shared/utils/validationUtils";

interface IKeysTranslate {
  keyTranslate: string;
}

type Props<T> = {
  listEducations: T[];
  stepForm: number;
  cvId: string;
  translateFields: ITranslateDataInputsCourseInformation;
  handleNextStage: any;
};

const NUMBER_STEP = 4;

const MESSAGE_ERROR =
  "Ocorreu um erro inesperado ao preencher os dados na tela.";

export const keysTranslateFieldsLabels: ITranslateDataInputsCourseInformation = {
  name: {keyTranslate: "school"},
  dateInit: {keyTranslate: "dateInit"},
  dateEnd: {keyTranslate: "dateEnd"},
  situation: {keyTranslate: "situation"},
  activities: {keyTranslate: "activities"},
  buttonAdd: {keyTranslate: "education"},
  buttonEdit: {keyTranslate: "education"},
  course: {keyTranslate: "course"},
};

export interface ITranslateDataInputsCourseInformation {
  name: IKeysTranslate;
  dateInit: IKeysTranslate;
  dateEnd: IKeysTranslate;
  activities: IKeysTranslate;
  buttonAdd: IKeysTranslate;
  buttonEdit?: IKeysTranslate;
  course: IKeysTranslate;
  situation?: IKeysTranslate;
}

const ENUM_STEP_INDEX = 3;

export const SITUATION_INIT: IBaseCombosFront = {
  description: "",
  id: "",
  language: "",
};

const Education = <T extends IEducationsFront>({
                                                 listEducations,
                                                 stepForm,
                                                 cvId,
                                                 handleNextStage,
                                                 translateFields,
                                               }: Props<T>) => {
  const countErrors = useAppSelector(selectCountErrors);
  const langAcronym = useAppSelector(selectLanguage);
  const languageName = LanguageTextByAcronym[langAcronym];
  const [listSituation, setListSituation] = useState<IBaseCombosFront[]>([]);
  const [shrinkValueName, setShrinkValueName] = useState<boolean>(false);
  const [shrinkValueCourse, setShrinkValueCourse] = useState<boolean>(false);
  const [shrinkValueActivities, setShrinkValueActivities] = useState<boolean>(false);

  const {t} = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/educacoes/${stepForm}`);
        if (!response.ok) {
          throw new Error("Failed to fetch education data");
        }
        const data = await response.json();
        setEducations(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [stepForm]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("cvForm.labels.school"),
      flex: 1,
      sortable: false,
    },
    {
      field: "course",
      headerName: t("cvForm.labels.course"),
      flex: 1,
      sortable: false,
    },
    {
      field: "dateInit",
      headerName: t("cvForm.labels.dateInit"),
      flex: 1,
      sortable: false,
      renderCell(params) {
        return formatDateStringENFromDateStringBR(params.value);
      },
    },
    {
      field: "dateEnd",
      headerName: t("cvForm.labels.dateEnd"),
      flex: 1,
      sortable: false,
      renderCell(params) {
        return formatDateStringENFromDateStringBR(params.value);
      },
    },
  ];
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedEducationDelete, setSelectedEducationDelete] =
    useState<IEducationsFront>();
  const [selectedEducationEdit, setSelectedEducationEdit] = useState<IFormEducation>();
  const [educations, setEducations] =
    useState<IEducationsFront[]>(listEducations);

  const [situation, setSituation] = useState<IBaseCombosFront>(SITUATION_INIT);

  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    trigger,
    formState: {errors, isSubmitSuccessful, isDirty, isValid},
  } = useForm<IFormEducation>({
    mode: "onChange",
  })

  useEffect(() => {
    if (selectedEducationEdit) {
      reset({...selectedEducationEdit});
      if (setSituation) {
        const situation = listSituation.filter(
          (st) => st.id === selectedEducationEdit.situation
        )[0];
        setSituation(situation as IBaseCombosFront);
      }
    }
   
    setShrinkValueName(true);
    setShrinkValueCourse(true);
    setShrinkValueActivities(true);
 
  }, [listSituation, reset, selectedEducationEdit, setSituation]);

  const handleCancel = useCallback(() => {
    reset({
      ...{
        activities: "",
        dateEnd: undefined,
        dateInit: undefined,
        id: "",
        name: "",
        course: "",
        situation: "",
      },
    });
    setSelectedEducationEdit(undefined);
    if (setSituation) setSituation({description: "", id: "", language: ""});
  }, [reset, setSelectedEducationEdit, setSituation]);

  useEffect(() => {
    if (stepForm !== NUMBER_STEP) {
      setSelectedEducationEdit(undefined);
      setSelectedEducationDelete(undefined);
    }
  }, [stepForm]);

  useEffect(() => {
    if (translateFields.situation) {
      dispatch(setLoading(true));
      FactoryRemoteSituation.get()
        .then((response) => {
          const listByLanguage = response.filter(
            (item) => item.linguagem.toLowerCase() === languageName
          );
          setListSituation(
            ComboMapper.arrayModelToDomain(listByLanguage).concat({
              description: "---",
              id: "",
              language: "",
            })
          );
          dispatch(setLoading(false));
          dispatch(decrementCountErros());
        })
        .catch((e) => {
          dispatch(setLoading(false));
          dispatch(incrementCountErrors());
          dispatch(setAlert({type: "error", open: true, message: e.message}));
        });
    }
  }, [dispatch, languageName, translateFields.situation]);

  const convertIFormToIEducationFront = (data: IFormEducation): IEducationsFront => {
    const {activities, dateEnd, dateInit, id, name, course, step} = data;
    return {
      activities,
      dateEnd,
      dateInit,
      id,
      name,
      course,
      situation,
      step,
    };
  };

  const handleOnSubmit = async (data: IFormEducation) => {
    const model = EducationMapper.toModel(convertIFormToIEducationFront(data));
    const experiencesNotData = educations.filter((ed) => ed.id !== model.id);
    dispatch(setLoading(true));
    try {
      if (selectedEducationEdit) {
        model.step = ENUM_STEP_INDEX;
        model.cvId = cvId;
        const resp = await FactoryEducationsPutCV.put(model.id, model);
        const domain = EducationMapper.toDomain(resp) as T;
        setSituation(SITUATION_INIT);
        setEducations([...experiencesNotData, domain]);
        setSelectedEducationEdit(undefined);
      } else {
        model.step = ENUM_STEP_INDEX;
        const resp = await FactoryEducationsPostCV.post(cvId, model);
        const domain = EducationMapper.toDomain(resp) as T;
        setEducations([...experiencesNotData, domain]);
        setSituation(SITUATION_INIT);
      }

      reset({
        activities: "",
        dateEnd: undefined,
        dateInit: undefined,
        id: "",
        name: "",
        course: "",
        situation: "",
      });
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          open: true,
          message: "Educação adicionada com sucesso.",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setAlert({
          open: true,
          message: error.message,
          type: "error",
        })
      );
      dispatch(setLoading(false));
    }
  };

  const handleDelete = async (id: string) => {
    const [selected] = educations.filter((item) => item.id === id);
    setSelectedEducationDelete(selected);
    setOpenDialog(true);
  };
  const handleEdit = async (id: string) => {
    const [edit] = educations.filter((item) => item.id === id);
    setSituation(SITUATION_INIT);
    setSelectedEducationEdit({...edit, situation: edit.situation.id});
  };

  const handleCloseModel = async (value: boolean) => {
    if (!value) {
      setOpenDialog(false);
      setSelectedEducationDelete(undefined);
    } else {
      dispatch(setLoading(true));
      try {
        if (selectedEducationDelete) {
          const model = EducationMapper.toModel(selectedEducationDelete);
          await FactoryDeleteEducationsCV.delete(cvId, model);
          const remove = educations.filter(
            (item) => item.id !== selectedEducationDelete.id
          );
          dispatch(setLoading(false));
          setEducations(remove);
          setOpenDialog(false);
          setSelectedEducationEdit(undefined);
          setSelectedEducationDelete(undefined);
          dispatch(
            setAlert({
              open: true,
              message: "Educação removida com sucesso.",
              type: "success",
            })
          );
        }
      } catch (error: any) {
        dispatch(setLoading(false));
        setOpenDialog(false);
        setSelectedEducationEdit(undefined);
        setSelectedEducationDelete(undefined);
        dispatch(
          setAlert({
            open: true,
            message: error.message,
            type: "error",
          })
        );
      }
    }
  };
  
  useEffect(() => {
    if (stepForm ===  4 ) {
      if (educations.length > 0) {
        handleNextStage(true, stepForm)
      } else {
        handleNextStage(false, stepForm)
      }
  }}, [stepForm, educations]);

  const mountLabelTranslate = (field: string): string => {
    const key =
      translateFields[field as keyof ITranslateDataInputsCourseInformation];
    return key ? t(`cvForm.labels.${key.keyTranslate}`) : "";
  };


  return (
    <ConditionalStepForm
      show={stepForm === NUMBER_STEP}
      showError={countErrors > 0}
      messageError={MESSAGE_ERROR}
    >
      <DialogConfirmOtherInformation
        handleOnclose={handleCloseModel}
        open={openDialog}
        question="Você deseja excluir essa educação?"
        title="Exclusão de Educação"
      />


      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getConfigDateLocale(langAcronym)}
      >
        <Box
          component="form"
          sx={{display: "flex", flexDirection: "column"}}
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <Grid container sx={{mt: "1rem", mb: "2rem"}} spacing={4}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={3}><Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Campo Obrigatório",
                  },
                  validate: (value) =>
                    value.trim().length >= 5 ||
                    "O campo deve ter no mínimo 5 caracteres.",
                  maxLength: {
                    value: 100,
                    message: "O campo deve ter no máximo 100 caracteres.",
                  },
                }}
                render={({field, fieldState}) => (
                  <TextField
                    {...field}
                    type="text"
                    InputLabelProps={{
                      shrink: shrinkValueName,
                    }}
                    onFocus={(_) => setShrinkValueName(true)}
                    onBlur={(_) =>
                      setShrinkValueName(_.target.value.length ? true : false)
                    }
                    label={mountLabelTranslate("name")}
                    sx={{maxWidth: 1, width: "100%"}}
                    size="small"
                    error={!!errors.name}
                    helperText={
                      errors.name ? (
                        <span data-cy="erro-name">
                      {errors.name?.message?.toString()}
                    </span>
                      ) : null
                    }
                    data-cy="name"
                  />
                )}
              />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="course"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Campo Obrigatório",
                    },
                    validate: {
                      minCharacters: (value) =>
                        value.trim().length >= 5
                        || "O campo deve ter no mínimo 5 caracteres.",
                      maxCharacters: (value) =>
                        value.trim().length <= 100
                        || "O campo deve ter no máximo 100 caracteres.",
                    },
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      type="text"
                      label={mountLabelTranslate("course")}
                      size="small"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error && (
                          <span data-cy="erro-course">{fieldState.error.message}</span>
                        )
                      }
                      InputLabelProps={{
                        shrink: shrinkValueCourse,
                      }}
                      onFocus={(_) => setShrinkValueCourse(true)}
                      onBlur={(e) =>
                        setShrinkValueCourse(e.target.value.length ? true : false)
                      }
                      sx={{maxWidth: 1, width: "100%"}}
                      data-cy="course"
                    />
                  )}
                />

              </Grid>
              <Grid container item spacing={2} xs={4}>
                <Grid item xs={6}>
                  <Controller
                    name="dateInit"
                    rules={{
                      required: {value: true, message: "Campo Obrigatório"},
                    }}
                    control={control}
                    render={({field, ...props}) => {
                      return (
                        <DatePicker
                          {...props}
                          label={mountLabelTranslate("dateInit")}
                          renderInput={(params: any) => (
                            <TextField
                              size="small"
                              data-cy="initialDate"
                              {...params}
                              error={!!errors.dateInit}
                              helperText={" " +
                              errors.dateInit ? (
                                <span data-cy="error-initial-date">
                                  {errors.dateInit?.message?.toString()}
                                </span>
                              ) : null
                              }
                            />
                          )}
                          value={
                            field.value
                              ? formatStringDateEnFromDateBR(field.value)
                              : null
                          }
                          onChange={(e) => {
                            field.onChange(e);
                            trigger('dateEnd').then((_) => _);
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>

                  <Controller
                    rules={{
                      validate: {
                        validateIfFieldShouldBeFilledBySituation: (value: string) => {
                          let situationValue: string | undefined = getValues()["situation"];
                          return validateDateEnd(value, situationValue);
                        },
                        validateIfDateIsGreaterThanDateInit: (value: string) => {
                          let dateInitValue: string = getValues()["dateInit"];
                          return dateEndIsSmallerThanDateInit(value, dateInitValue);
                        }
                      }
                    }}
                    name="dateEnd"
                    control={control}
                    render={({field, ...props}) => {
                      return (
                        <DatePicker
                          {...props}
                          label={mountLabelTranslate("dateEnd")}
                          renderInput={(params: any) => (
                            <TextField
                              size="small"
                              data-cy="finalDate"
                              {...params}
                              error={!!errors.dateEnd}
                              helperText={" " +
                              errors.dateEnd ? (
                                <span data-cy="error-final-date">
                                  {errors.dateEnd?.message?.toString()}
                                </span>
                              ) : null
                              }
                            />
                          )}
                          value={
                            field.value
                              ? formatStringDateEnFromDateBR(field.value)
                              : null
                          }
                          onChange={(e) => field.onChange(e)}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={2} spacing={2} alignItems="flex-start">
                <Grid item xs={12}>
                  {translateFields.situation !== undefined &&
                  listSituation.length ? (
                    <FormControl fullWidth size="small">
                      <InputLabel id={`select-situation`}>
                        {mountLabelTranslate("situation")}
                      </InputLabel>
                      <Controller
                        name="situation"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Campo Obrigatório",
                          },
                        }}
                        render={({field}) => (
                          <Select
                            id="select-situation"
                            data-cy="situation"
                            label="situation"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              if (setSituation && e.target.value !== "") {
                                setSituation(
                                  listSituation.filter(
                                    (st) => st.id === e.target.value
                                  )[0]
                                );
                              }
                              trigger('dateEnd').then((_) => _);
                            }}
                            value={field.value ? field.value : ""}
                            fullWidth
                            sx={{maxWidth: 1, width: "100%", margin: "auto"}}
                            MenuProps={{disableScrollLock: true}}
                            error={!!errors.situation}
                          >
                            {listSituation.map((item, index) => (
                              <MenuItem
                                data-cy={`${item.description}`}
                                key={index}
                                value={item ? item.id : ""}
                              >
                                {item.description}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <FormHelperText
                        data-cy="erro"
                        sx={[
                          (theme) => ({
                            color: theme.palette.dbRed.main,
                          }),
                        ]}
                      >
                        {errors.situation ? (
                          <span data-cy="error-situation">
                          {errors.situation?.message?.toString()}
                        </span>
                        ) : null}
                      </FormHelperText>
                    </FormControl>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="activities"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Campo Obrigatório",
                    },
                    validate: {
                      validateIfFieldHasMoreThan15Chars: (value: string) => {
                        return value.trim().length >= 15 || "O campo deve ter no mínimo 15 caracteres."
                      },
                      validateIfFieldHasMoreThan2000Chars: (value: string) => {
                        if (value.trim().length > 2000) {
                          return "O campo deve ter no máximo 2000 caracteres."
                        }
                      },
                    }
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      data-cy="activities"
                      label={mountLabelTranslate("activities")}
                      InputLabelProps={{
                        shrink: shrinkValueActivities,
                      }}
                      onFocus={(_) => setShrinkValueActivities(true)}
                      onBlur={(_) =>
                        setShrinkValueActivities(_.target.value.length ? true : false)
                      }
                      multiline
                      minRows={5}
                      fullWidth
                      variant="outlined"
                      placeholder={t("cvForm.placeholder.text2000Caracters")}
                      size="small"
                      error={!!errors.activities}
                      helperText={
                        errors.activities ? (
                          <span data-cy="erro-activities">
                      {errors.activities?.message?.toString()}
                    </span>
                        ) : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="flex-end"
                alignItems="flex-end"
                display="flex"
                columnGap={2}
              >
                <Button
                  variant="contained"
                  type="button"
                  color="dbRed"
                  sx={{display: Boolean(selectedEducationEdit) ? "initial" : "none"}}
                  onClick={handleCancel}
                  data-cy="botaoTestando"
                >
                  {t(`cvForm.buttons.clear.reset`)}
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  color="dbDarkBlue"
                  disabled={!isValid}
                  data-cy="buttonSubmitEducation"
                >
                  {t(
                    selectedEducationEdit
                      ? `cvForm.buttons.edit.${translateFields?.buttonEdit?.keyTranslate}`
                      : `cvForm.buttons.add.${translateFields.buttonAdd.keyTranslate}`
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
      <Divider/>
      <ConditionalStepForm show={educations.length > 0}>
        <span data-cy="tabelaEducacao">
          <TableOtherInformation
            columns={columns}
            rows={educations}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            pageSize={5}
          />
        </span>
      </ConditionalStepForm>
    </ConditionalStepForm>
  );
};

export default Education;