import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

type Props = {
  handleOnclose: (value: boolean) => void;
  open: boolean;
};

const DialogConfirm = ({ open, handleOnclose }: Props) => {
  const handleClickCancel = () => handleOnclose(false);

  const handleClickOk = () => handleOnclose(true);

  return (
    <Dialog
      open={open}
      onClose={handleClickCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Exclusão de currículo</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você deseja excluir este currículo?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancel}>Cancelar</Button>
        <Button onClick={handleClickOk} autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirm;
