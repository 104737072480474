import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import {
  useAppDispatch,
  useAppSelector,
} from "configuration/reduxToolkit/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  decrementCountErros,
  incrementCountErrors,
  selectCountErrors,
  selectLanguage,
  setAlert,
  setLoading,
} from "reduxFeatures/app/appSlice";
import { IBaseCombosFront } from "services/combos/interfaces";
import { FactoryDeleteCertificationsCV } from "services/cv/certification/remoteCertificationsDeleteCV";
import { FactoryCertificationsPostCV } from "services/cv/certification/remoteCertificationsPostCV";
import { FactoryCertificationsPutCV } from "services/cv/certification/remoteCertificationsPutCV";
import {
  ICertificationsFront,
  IForm,
  ITranslaterDataInputsOtherInformation,
} from "services/cv/interfaces";
import { CertificationMapper } from "services/cv/mappers";
import {
  LanguageTextByAcronym,
  formatDateStringENFromDateStringBR,
  formatStringDateEnFromDateBR,
  getConfigDateLocale,
} from "shared/utils";
import ConditionalStepForm from "../ConditionalStepForm";
import DialogConfirmOtherInformation from "../OtherInformations/DialogConfirmOtherInformation";

import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TableOtherInformation from "../OtherInformations/TableOtherInformation/indx";
import { FactoryRemoteSituation } from "services/combos/remoteSituation";
import { ComboMapper } from "services/combos/comboMapper";
import {
  validateDateEnd,
  dateEndIsSmallerThanDateInit,
} from "../../../../shared/utils/validationUtils";

type Props<T> = {
  stepForm: number;
  cvId: string;
  listCertifications: T[];
};

const MESSAGE_ERROR =
  "Ocorreu um erro inesperado ao preencher os dados na tela.";

const SITUATION_INIT: IBaseCombosFront = {
  description: "",
  id: "",
  language: "",
};

const NUMBER_STEP = 5;

export const keysTranslaterFieldsLabels: ITranslaterDataInputsOtherInformation =
  {
    name: { keyTranslater: "school" },
    dateInit: { keyTranslater: "dateInit" },
    dateEnd: { keyTranslater: "dateEnd" },
    activities: { keyTranslater: "activities" },
    buttonAdd: { keyTranslater: "certification" },
    buttonEdit: { keyTranslater: "certification" },
    other: { keyTranslater: "certification" },
    situation: { keyTranslater: "situation" },
  };

const ENUM_STEP_INDEX = 4;

const Certification = <T extends ICertificationsFront>({
  stepForm,
  cvId,
  listCertifications,
}: Props<T>) => {
  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors, isSubmitSuccessful, isDirty, isValid },
  } = useForm<IForm>({
    mode: "onChange",
  });

  const countErrors = useAppSelector(selectCountErrors);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedCertificationsDelete, setSelectedCertificationsDelete] =
    useState<ICertificationsFront>();

  const [selectedCertificationsEdit, setSelectedCertificationsEdit] =
    useState<IForm>();

  const [situation, setSituation] = useState<IBaseCombosFront>(SITUATION_INIT);

  const [certifications, setCertifications] =
    useState<ICertificationsFront[]>(listCertifications);

  const [shrinkValueName, setShrinkValueName] = useState<boolean>(false);
  const [shrinkValueCertification, setShrinkValueCertification] = useState<boolean>(false);
  const [shrinkValueActivities, setShrinkValueActivities] = useState<boolean>(false);
 
  const [isRequired, setIsRequired] = useState<boolean>(true);
  const [listSituation, setListSituation] = useState<IBaseCombosFront[]>([]);
  const langAcronym = useAppSelector(selectLanguage);
  const languageName = LanguageTextByAcronym[langAcronym];

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("cvForm.labels.school"),
      flex: 1,
      sortable: false,
    },
    {
      field: "other",
      headerName: t("cvForm.labels.course"),
      flex: 1,
      sortable: false,
    },
    {
      field: "dateInit",
      headerName: t("cvForm.labels.dateInit"),
      flex: 1,
      sortable: false,
      renderCell(params) {
        return formatDateStringENFromDateStringBR(params.value);
      },
    },
    {
      field: "dateEnd",
      headerName: t("cvForm.labels.dateEnd"),
      flex: 1,
      sortable: false,
      renderCell(params) {
        return formatDateStringENFromDateStringBR(params.value);
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/certificacoes/${stepForm}`);
        if (!response.ok) {
          throw new Error("Failed to fetch education data");
        }
        const data = await response.json();
        setCertifications(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [stepForm]);

  useEffect(() => {
    if (stepForm !== NUMBER_STEP) {
      setSelectedCertificationsEdit(undefined);
      setSelectedCertificationsDelete(undefined);
    }
  }, [stepForm]);

  const convertIFormToICertificationsFront = (
    data: IForm
  ): ICertificationsFront => {
    const { activities, dateEnd, dateInit, id, name, other, step } = data;
    return {
      activities,
      dateEnd,
      dateInit,
      id,
      name,
      other,
      situation,
      step,
    };
  };

  useEffect(() => {
    if (selectedCertificationsEdit) {
      reset({ ...selectedCertificationsEdit });
      if (setSituation) {
        const situation = listSituation.filter(
          (st) => st.id === selectedCertificationsEdit.situation
        )[0];

        setSituation(situation as IBaseCombosFront);
      }
    }
    setShrinkValueName(true);
    setShrinkValueCertification(true);
    setShrinkValueActivities(true);

  }, [listSituation, reset, selectedCertificationsEdit, setSituation]);

  const handleCancel = useCallback(() => {
    reset({
      ...{
        activities: "",
        dateEnd: undefined,
        dateInit: undefined,
        id: "",
        name: "",
        other: "",
        situation: "",
        status: false,
      },
    });
    setIsRequired(true);
    setSelectedCertificationsEdit(undefined);
  }, [reset, setSelectedCertificationsEdit]);

  const handleOnSubmit = async (data: IForm) => {
    data.step = ENUM_STEP_INDEX;

    const model = CertificationMapper.toModel(
      convertIFormToICertificationsFront(data)
    );

    const experiencesNotData = certifications.filter(
      (ed) => ed.id !== model.id
    );

    dispatch(setLoading(true));
    try {
      if (selectedCertificationsEdit) {
        model.step = ENUM_STEP_INDEX;
        model.cvId = cvId;
        const resp = await FactoryCertificationsPutCV.put(model.id, model);
        const domain = CertificationMapper.toDomain(resp);
        setSituation(SITUATION_INIT);
        setCertifications([...experiencesNotData, domain]);
        setSelectedCertificationsEdit(undefined);
      } else {
        model.step = ENUM_STEP_INDEX;
        const resp = await FactoryCertificationsPostCV.post(cvId, model);
        const domain = CertificationMapper.toDomain(resp);
        setCertifications([...experiencesNotData, domain]);
        setSituation(SITUATION_INIT);
      }
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          open: true,
          message: "Certificação adicionada com sucesso.",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setAlert({
          open: true,
          message: error.message,
          type: "error",
        })
      );
      dispatch(setLoading(false));
    }
  };

  const handleDelete = async (id: string) => {
    const [selected] = certifications.filter((item) => item.id === id);
    setSelectedCertificationsDelete(selected);
    setOpenDialog(true);
  };

  const handleEdit = async (id: string) => {
    const [edit] = certifications.filter((item) => item.id === id);
    setSituation(SITUATION_INIT);
    setSelectedCertificationsEdit({ ...edit, situation: edit.situation.id });
  };

  const handleCloseModel = async (value: boolean) => {
    if (!value) {
      setOpenDialog(false);
      setSelectedCertificationsDelete(undefined);
    } else {
      dispatch(setLoading(true));
      try {
        if (selectedCertificationsDelete) {
          const model = CertificationMapper.toModel(
            selectedCertificationsDelete
          );
          await FactoryDeleteCertificationsCV.delete(cvId, model);
          const remove = certifications.filter(
            (item) => item.id !== selectedCertificationsDelete.id
          );
          dispatch(setLoading(false));
          setCertifications(remove);
          setOpenDialog(false);
          setSelectedCertificationsEdit(undefined);
          setSelectedCertificationsDelete(undefined);
          dispatch(
            setAlert({
              open: true,
              message: "Certificação removida com sucesso.",
              type: "success",
            })
          );
        }
      } catch (error: any) {
        dispatch(setLoading(false));
        setOpenDialog(false);
        setSelectedCertificationsEdit(undefined);
        setSelectedCertificationsDelete(undefined);
        dispatch(
          setAlert({
            open: true,
            message: error.message,
            type: "error",
          })
        );
      }
    }
  };

  useEffect(() => { 
    if (keysTranslaterFieldsLabels.situation) {
      dispatch(setLoading(true));
      FactoryRemoteSituation.get()
        .then((response) => {
          const listByLanguage = response.filter(
            (item) => item.linguagem.toLowerCase() === languageName
          );
          
          setListSituation(
            ComboMapper.arrayModelToDomain(listByLanguage).concat({
              description: "---",
              id: "",
              language: "",
            })
          );
          dispatch(setLoading(false));
          dispatch(decrementCountErros());
        })
        .catch((e) => {
          dispatch(setLoading(false));
          dispatch(incrementCountErrors());
          dispatch(setAlert({ type: "error", open: true, message: e.message }));
        });
    }
  }, [dispatch, languageName, keysTranslaterFieldsLabels.situation]);

  useEffect(() => {
    handleCancel();
    setShrinkValueName(true);
    setShrinkValueCertification(true);
    setShrinkValueActivities(true);
  }, [handleCancel, isSubmitSuccessful, reset]);

  useEffect(() => {
    if (keysTranslaterFieldsLabels.situation) {
      dispatch(setLoading(true));
      FactoryRemoteSituation.get()
        .then((response) => {
          const listByLanguage = response.filter(
            (item) => item.linguagem.toLowerCase() === languageName
          );
               
          setListSituation(
            ComboMapper.arrayModelToDomain(listByLanguage).concat({
              description: "---",
              id: "",
              language: "",
            })
          );
          dispatch(setLoading(false));
          dispatch(decrementCountErros());
        })
        .catch((e) => {
          dispatch(setLoading(false));
          dispatch(incrementCountErrors());
          dispatch(setAlert({ type: "error", open: true, message: e.message }));
        });
    }
  }, [dispatch, languageName, keysTranslaterFieldsLabels.situation]);

  const mountLabelTranslater = (field: string): string => {
    const key =
      keysTranslaterFieldsLabels[
        field as keyof ITranslaterDataInputsOtherInformation
      ];
    return key ? t(`cvForm.labels.${key.keyTranslater}`) : "";
  };

  return (
    <ConditionalStepForm
      show={stepForm === NUMBER_STEP}
      showError={countErrors > 0}
      messageError={MESSAGE_ERROR}
    >
      <DialogConfirmOtherInformation
        handleOnclose={handleCloseModel}
        open={openDialog}
        question="Você deseja excluir essa certificação?"
        title="Exclusão de Certificação"
      />
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getConfigDateLocale(langAcronym)}
      >
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <Grid container sx={{ mt: "1rem", mb: "2rem" }} spacing={4}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  type="text"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Campo Obrigatório",
                    },
                    minLength: {
                      value: 5,
                      message: "O campo deve ter no mínimo 5 caracteres.",
                    },
                    maxLength: {
                      value: 100,
                      message: "O campo deve ter no máximo 100 caracteres.",
                    },
                  })}
                  InputLabelProps={{
                    shrink: shrinkValueName,
                  }}
                  onFocus={(_) => setShrinkValueName(true)}
                  onBlur={(_) =>
                    setShrinkValueName(_.target.value.length ? true : false)
                  }
                  label={mountLabelTranslater("name")}
                  sx={{ maxWidth: 1, width: "100%" }}
                  size="small"
                  error={!!errors.name}
                  helperText={
                    errors.name ? (
                      <span data-cy="erro-name">
                        {errors.name?.message?.toString()}
                      </span>
                    ) : null
                  }
                  data-cy="name"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="text"
                  {...register("other", {
                    required: {
                      value: true,
                      message: "Campo Obrigatório",
                    },
                    minLength: {
                      value: 5,
                      message: "O campo deve ter no mínimo 5 caracteres.",
                    },
                    maxLength: {
                      value: 100,
                      message: "O campo deve ter no máximo 100 caracteres.",
                    },
                  })}
                  InputLabelProps={{
                    shrink: shrinkValueCertification,
                  }}
                  onFocus={(_) => setShrinkValueCertification(true)}
                  onBlur={(_) =>
                    setShrinkValueCertification(_.target.value.length ? true : false)
                  }
                  label={mountLabelTranslater("other")}
                  sx={{ maxWidth: 1, width: "100%" }}
                  size="small"
                  error={!!errors.other}
                  helperText={
                    errors.other ? (
                      <span data-cy="erro-other">
                        {errors.other?.message?.toString()}
                      </span>
                    ) : null
                  }
                  data-cy="other"
                />
              </Grid>
              <Grid container item spacing={2} xs={4}>
                <Grid item xs={6}>
                  <Controller
                    name="dateInit"
                    rules={{
                      required: { value: true, message: "Campo Obrigatório" },
                    }}
                    control={control}
                    render={({ field, ...props }) => {
                      return (
                        <DatePicker
                          {...props}
                          label={mountLabelTranslater("dateInit")}
                          renderInput={(params: any) => (
                            <TextField
                              size="small"
                              data-cy="initialDate"
                              {...params}
                              error={!!errors.dateInit}
                              helperText={
                                errors.dateInit ? (
                                  <span data-cy="error-initial-date">
                                    {errors.dateInit?.message?.toString()}
                                  </span>
                                ) : null
                              }
                            />
                          )}
                          value={
                            field.value
                              ? formatStringDateEnFromDateBR(field.value)
                              : null
                          }
                          onChange={(e) => {
                            field.onChange(e);
                            trigger("dateEnd").then((_) => _);
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    rules={{
                      validate: {
                        validateIfFieldShouldBeFilledBySituation: (
                          value: string
                        ) => {
                          let situationValue: string | undefined =
                            getValues()["situation"];
                          return validateDateEnd(value, situationValue);
                        },
                        validateIfDateIsGreaterThanDateInit: (
                          value: string
                        ) => {
                          let dateInitValue: string = getValues()["dateInit"];
                          return dateEndIsSmallerThanDateInit(
                            value,
                            dateInitValue
                          );
                        },
                      },
                    }}
                    name="dateEnd"
                    control={control}
                    render={({ field, ...props }) => {
                      return (
                        <DatePicker
                          {...props}
                          label={mountLabelTranslater("dateEnd")}
                          renderInput={(params: any) => (
                            <TextField
                              size="small"
                              data-cy="finalDate"
                              {...params}
                              error={!!errors.dateEnd}
                              helperText={
                                errors.dateEnd ? (
                                  <span data-cy="error-final-date">
                                    {errors.dateEnd?.message?.toString()}
                                  </span>
                                ) : null
                              }
                            />
                          )}
                          value={
                            field.value
                              ? formatStringDateEnFromDateBR(field.value)
                              : null
                          }
                          onChange={(e) => field.onChange(e)}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={2} spacing={2} alignItems="flex-start">
                <Grid item xs={12}>
                  {keysTranslaterFieldsLabels.status !== undefined ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        label={mountLabelTranslater("status")}
                        control={
                          <Controller
                            name="status"
                            control={control}
                            render={({ field: { value, ...field } }) => (
                              <Checkbox
                                color="dbDarkBlue"
                                {...field}
                                checked={!!value}
                                onClick={() => setIsRequired(!!value)}
                              />
                            )}
                          />
                        }
                      />
                    </Box>
                  ) : null}
                  {keysTranslaterFieldsLabels.situation !== undefined &&
                  listSituation.length ? (
                    <FormControl fullWidth size="small">
                      <InputLabel id={`select-situation`}>
                        {mountLabelTranslater("situation")}
                      </InputLabel>
                      <Controller
                        name="situation"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Campo Obrigatório",
                          },
                        }}
                        render={({ field }) => (
                          <Select
                            inputProps={{ "data-testid": "situation" }}
                            id="select-situation"
                            data-cy="situation"
                            label="situation"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              if (setSituation && e.target.value !== "") {
                                setSituation(
                                  listSituation.filter(
                                    (st) => st.id === e.target.value
                                  )[0]
                                );
                              }
                              trigger("dateEnd").then((_) => _);
                            }}
                            value={field.value ? field.value : ""}
                            fullWidth
                            sx={{ maxWidth: 1, width: "100%" }}
                            MenuProps={{ disableScrollLock: true }}
                            error={!!errors.situation}
                          >
                            {listSituation.map((item, index) => (
                              <MenuItem
                                data-cy={`${item.description}`}
                                key={index}
                                value={item ? item.id : ""}
                              >
                                {item.description}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <FormHelperText
                        data-cy="erro"
                        sx={[
                          (theme) => ({
                            color: theme.palette.dbRed.main,
                          }),
                        ]}
                      >
                        {errors.situation ? (
                          <span data-cy="error-situation">
                            {errors.situation?.message?.toString()}
                          </span>
                        ) : null}
                      </FormHelperText>
                    </FormControl>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  data-cy="activities"
                  {...register("activities", {
                    required: {
                      value: true,
                      message: "Campo Obrigatório.",
                    },
                    validate: {
                      validateIfFieldHasMoreThan15Chars: (value: string) => {
                        return (
                          value.trim().length >= 15 ||
                          "O campo deve ter no mínimo 15 caracteres."
                        );
                      },
                      validateIfFieldHasMoreThan2000Chars: (value: string) => {
                        if (value.trim().length > 2000) {
                          return "O campo deve ter no máximo 2000 caracteres.";
                        }
                      },
                    },
                  })}
                  label={mountLabelTranslater("activities")}
                  InputLabelProps={{
                    shrink: shrinkValueActivities,
                  }}
                  onFocus={(_) => setShrinkValueActivities(true)}
                  onBlur={(_) =>
                    setShrinkValueActivities(_.target.value.length ? true : false)
                  }
                  multiline
                  minRows={5}
                  fullWidth
                  variant="outlined"
                  placeholder={t("cvForm.placeholder.text2000Caracters")}
                  size="small"
                  error={!!errors.activities}
                  helperText={
                    errors.activities ? (
                      <span data-cy="erro-activities">
                        {errors.activities?.message?.toString()}
                      </span>
                    ) : null
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="flex-end"
                alignItems="flex-end"
                display="flex"
                columnGap={2}
              >
                <Button
                  variant="contained"
                  type="button"
                  color="dbRed"
                  sx={{
                    display: Boolean(selectedCertificationsEdit)
                      ? "initial"
                      : "none",
                  }}
                  onClick={handleCancel}
                  data-cy="botaoVoltar"
                >
                  {t(`cvForm.buttons.clear.reset`)}
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  color="dbDarkBlue"
                  disabled={!isValid}
                  data-cy="botaoSubmit"
                >
                  {t(
                    selectedCertificationsEdit
                      ? `cvForm.buttons.edit.${keysTranslaterFieldsLabels?.buttonEdit?.keyTranslater}`
                      : `cvForm.buttons.add.${keysTranslaterFieldsLabels.buttonAdd.keyTranslater}`
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
      <Divider />
      <ConditionalStepForm show={certifications.length > 0}>
        <span data-cy="tabelaCertificacao">
          <TableOtherInformation
            columns={columns}
            rows={certifications}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            pageSize={5}
          />
        </span>
      </ConditionalStepForm>
    </ConditionalStepForm>
  );
};

export default Certification;
