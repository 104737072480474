import {format, parse, isDate} from "date-fns";
import {ptBR, enUS} from "date-fns/locale";

export const FORMAT_EN_US = "yyyy-MM-dd";
export const FORMAT_PT_BR = "dd/MM/yyyy";
export const REGEX_EMAIL = new RegExp(
  /^[a-zA-Z0-9.!#$%&'_^`{}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
export const MESSAGE_INVALID_EMAIL = "E-mail inválido.";
export const MESSAGE_REQUIRED_FIELD = "Campo obrigatório.";

export const LanguageAcronym: { [key: string]: string } = {
  portugues: "pt-BR",
  english: "en-US",
};

export const LanguageTextByAcronym: { [key: string]: string } = {
  "pt-BR": "portugues",
  "en-US": "english",
};

export const LanguageAcronymFormat: { [key: string]: string } = {
  "pt-BR": "dd/MM/yyyy",
  "en-US": "yyyy-MM-dd",
};

const localeDate: { [key: string]: any } = {
  "pt-BR": ptBR,
  "en-US": enUS,
};

export const getConfigDateLocale = (locale: string) => localeDate[locale];

export const setAttribute = <T extends Object, U extends keyof T>(
  obj: T,
  key: U,
  value: T[U]
) => {
  obj[key] = value;
  return obj;
};

export const convertKeysObject = <
  T extends Object,
  U extends Object,
  R extends Object
>(
  objectBaseConvertion: T,
  baseConvertion: U,
  newObject: R
): R => {
  Object.keys(objectBaseConvertion).forEach((key: string) => {
    const dName = baseConvertion[key as keyof U];
    const value: any = objectBaseConvertion[key as keyof T];
    newObject[dName as keyof R] = value ?? "";
  });

  return newObject;
};

export const formatDateStringENFromDateStringBR = (
  dateString: string | undefined | null
): string => {
  return dateString
    ? format(parse(dateString, FORMAT_EN_US, new Date()), FORMAT_PT_BR)
    : "";
};

export const formatDateStringBRFromDateStringEN = (
  dateString: string | undefined | null
): string => {
  return dateString
    ? format(parse(dateString, FORMAT_PT_BR, new Date()), FORMAT_EN_US)
    : "";
};

export const formatDateStringFromDateStringEn = (
  dateString: string | undefined | null
): string => {
  return dateString ? format(new Date(dateString), FORMAT_EN_US) : "";
};

export const formatDateToStringDateBR = (date: Date | null) =>
  date ? format(date, FORMAT_PT_BR) : null;

export const formatDateToStringDateEN = (date: Date | null) =>
  date ? format(date, FORMAT_EN_US) : null;

export const formatStringDateBRToDate = (date: string | null | undefined) => {
  return date ? parse(date, FORMAT_PT_BR, new Date()) : null;
};

export const formatStringDateENToDate = (date: string | null | undefined) => {
  return date ? parse(date, FORMAT_EN_US, new Date()) : null;
};

export const formatStringDateEnFromDateBR = (
  date: string | null | undefined
) => {
  if (!date) return null;
  if (isDate(date)) return date;
  const dtEN = formatStringDateENToDate(date);
  const dtStrBR = formatDateToStringDateBR(dtEN);
  return formatStringDateBRToDate(dtStrBR);
};

export const isEnvironment = (env: string) =>
  process.env.REACT_APP_ENV?.toLocaleUpperCase() === env.toLowerCase();

export const formatDateENUsMonthNameAndYear = (
  date: string | null,
  lang: string
): string => {
  if (!date) return "";
  const internalDate = parse(date, FORMAT_EN_US, new Date());
  const month = format(internalDate, "MMM", {locale: localeDate[lang]});
  const year = format(internalDate, "yyyy", {locale: localeDate[lang]});
  return `${capitalizeString(month)}/${year}`;
};

export const formatDateENUsYear = (date: string | null): string => {
  if (!date) return "";
  const newDate = parse(date, FORMAT_EN_US, new Date());
  return format(newDate, "yyyy");
};

export const capitalizeString = (text: string): string => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};
