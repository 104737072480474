import { IProficiencies, IProficienciesFront } from "../interfaces";
import { KnowledgeMapper } from "./knowledgeMapper";
import { LanguagesMapper } from "./languagesMapper";

interface IProficienciesMapper {
    toDomain: (data: IProficiencies) => IProficienciesFront;
    toModel: (data: IProficienciesFront) => IProficiencies;
}

const convertModelToDomain = (proficiencias: IProficiencies): IProficienciesFront => {
    const listaConhecimentos = proficiencias.conhecimentos;
    const listaLinguas = proficiencias.linguas;
    const step = proficiencias.step = 1;

    const knowledges = KnowledgeMapper.arrayToDomain(listaConhecimentos);
    const languages = LanguagesMapper.arrayToDomain(listaLinguas);
    return { knowledges, languages, step };
}

const convertDomainToModel = (proficiencies: IProficienciesFront): IProficiencies => {
    const knowledgeList = proficiencies.knowledges;
    const languageList = proficiencies.languages;
    const step = proficiencies.step = 1;

    const conhecimentos = KnowledgeMapper.arrayToModel(knowledgeList);
    const linguas = LanguagesMapper.arrayToModel(languageList);
    return { conhecimentos, linguas, step };
}

export const ProficienciesMapper: IProficienciesMapper = {
    toDomain: convertModelToDomain,
    toModel: convertDomainToModel,
}