import { AxiosClient } from "configuration/axios/client";
import {
  HttpClient,
  HttpMessageError,
  HttpStatusCode,
} from "configuration/http/httpClient";
import { IGetListCV, IQueryModel, IListCV } from "./interfaces";

export class RemoteGetCV implements IGetListCV {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IListCV>
  ) {}

  async get(query?: IQueryModel): Promise<IListCV> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "get",
      params: query,
    });
    const remoteData = response.body || ({} as IListCV);
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return remoteData;
      case HttpStatusCode.noContent:
        return {} as IListCV;
      case HttpStatusCode.badRequest:
        throw new Error(HttpMessageError[400]);
      default:
        throw new Error(HttpMessageError[500]);
    }
  }
}

const FactoryGetListCV: IGetListCV = new RemoteGetCV(
  "/curriculos",
  new AxiosClient()
);

export { FactoryGetListCV };
