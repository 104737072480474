import { formatDateStringFromDateStringEn } from "shared/utils";
import { IExperiences, IExperiencesFront } from "../interfaces";

interface IExperiencesMapper {
  toDomain: (data: IExperiences) => IExperiencesFront;
  toModel: (data: IExperiencesFront) => IExperiences;
}

const convertModelToDomain = (data: IExperiences): IExperiencesFront => {
  return {
    id: data.id,
    step: data.step,
    name: data.nomeEmpresa,
    other: data.cargo,
    dateInit: data.dataInicio,
    dateEnd: data.dataTermino,
    activities: data.atividades,
    status: data.status,
  };
};

const convertDomainToModel = (data: IExperiencesFront): IExperiences => {
  return {
    id: data.id,
    step: data.step,
    nomeEmpresa: data.name,
    cargo: data.other,
    dataInicio: formatDateStringFromDateStringEn(data.dateInit),
    dataTermino: formatDateStringFromDateStringEn(data.dateEnd),
    atividades: data.activities,
    status: data.status,
  };
};

export const ExperiencesMapper: IExperiencesMapper = {
  toDomain: convertModelToDomain,
  toModel: convertDomainToModel,
};
