import { Box, Button, Divider } from "@mui/material";
import { useState } from "react";

import ConditionalStepForm from "./ConditionalStepForm";
import Header from "./Header";
import LoadingSkeleton from "../../LoadingSkeleton";

import PersonalInformation from "./PersonalInfomation";

import { IPersonalInformationFront, ListCVBase } from "services/cv/interfaces";

import { ListCVBaseFront } from "services/cv/interfaces";

import {
  useAppDispatch,
  useAppSelector,
} from "configuration/reduxToolkit/hooks";

import {
  resetCountErros,
  selectCountErrors,
  setAlert,
  setLanguage,
  setLoading,
  TLanguage,
} from "reduxFeatures/app/appSlice";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { LanguageAcronym } from "shared/utils";
import { PersonalInformationMapper } from "services/cv/mappers";
import { FactoryPostPersonalInformationCV } from "services/cv/personalInformation/remotePersonalInformationPostCV";
import { useNavigate } from "react-router-dom";

import { FactoryPutPersonalInformationCV } from "services/cv/personalInformation/remotePersonalInformationPutCV";
import Certification, { keysTranslaterFieldsLabels } from "./Certification";
import Step2 from "pages/cv/create/step-2";
import Education from "./Education";
import { keysTranslateFieldsLabels } from "./Education";
import Experiencies from "./Experience";

const PERSONAL_INFORMATION_INIT: IPersonalInformationFront = {
  email: "",
  name: "",
  role: "",
  language: "PORTUGUES",
  summary: "",
  step: 0,
};

type Props = {
  cvSelected?: ListCVBaseFront;
};

const FormCV = ({ cvSelected = {} as ListCVBaseFront }: Props) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<IPersonalInformationFront>({
    defaultValues: {
      ...{ ...PERSONAL_INFORMATION_INIT, ...cvSelected.personalInformation },
    },
    mode: "all",
  });

  const dispatch = useAppDispatch();
  const countErrors = useAppSelector(selectCountErrors);
  const [stepsForm, setStepsForm] = useState<number>(1);
  const [newCvId, setNewCvId] = useState<string>(cvSelected.id);

  const lang = watch("language");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetCountErros());
  }, [dispatch]);

  useEffect(() => {
    const acronym = LanguageAcronym[lang.toLowerCase()];
    dispatch(setLanguage(acronym as TLanguage));
  }, [dispatch, lang]);

  const handleNextStepForm = () => {
    setStepsForm(stepsForm + 1);
  };
  const handlePrevStepForm = () => {
    dispatch(resetCountErros());
    setStepsForm(stepsForm - 1);
  };

  const handlerCallSucessMessage = (message: string) => {
    dispatch(
      setAlert({
        open: true,
        message: message,
        type: "success",
        seconds: 2000,
      })
    );
  };

  const handlerCallErrorMessage = (message: string) => {
    dispatch(
      setAlert({
        open: true,
        message: message,
        type: "error",
        seconds: 2000,
      })
    );
  };

  const onSaveFirstStep = async (data: IPersonalInformationFront) => {
    dispatch(resetCountErros());
    dispatch(setLoading(true));
    const model = PersonalInformationMapper.toModel(data);
    try {
      if (!newCvId) {
        const response: ListCVBase =
          await FactoryPostPersonalInformationCV.post(model);
        dispatch(setLoading(false));
        setNewCvId(response.id);
        handlerCallSucessMessage("Dados salvos com sucesso!");
        setStepsForm(stepsForm + 1);
      } else {
        const id = newCvId ?? cvSelected.id;
        const response: ListCVBase = await FactoryPutPersonalInformationCV.put(
          id,
          model
        );
        dispatch(setLoading(false));
        setNewCvId(response.id);
        setStepsForm(stepsForm + 1);
        handlerCallSucessMessage("Dados atualizados com sucesso!");
      }
    } catch (error: any) {
      handlerCallErrorMessage(error.message);
      dispatch(setLoading(false));
    }
  };

  const [nextStage, setNextStage] = useState<boolean>(true);

  const handleNextStage = (b: boolean, stepRequest: number) => {
    if(stepRequest == stepsForm){
      setNextStage(b);
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box hidden={stepsForm === 2}>
        <Header maxSteps={5} currentStep={stepsForm} />
      </Box>

      <PersonalInformation
        register={register}
        errors={errors}
        control={control}
        handleSubmit={handleSubmit}
        handOnClick={onSaveFirstStep}
        showStep={stepsForm === 1}
      />

      <Step2
          cvId={newCvId ?? cvSelected.id}
          knowledge={cvSelected.knowledge}
          languages={cvSelected.languages}
          stepsForm={stepsForm}
          setStepsForm={setStepsForm}
      />
      
      <Experiencies
        listExperiences={cvSelected.experiences || []}
        cvId={newCvId}
        stepForm={stepsForm}
        handleNextStage={handleNextStage}
      />
    
      <Education
        listEducations={cvSelected.educations || []}
        cvId={newCvId}
        stepForm={stepsForm}
        handleNextStage={handleNextStage}
        translateFields={keysTranslateFieldsLabels}
      />
    
      <Certification
        listCertifications={cvSelected.certifications || []}
        cvId={newCvId}
        stepForm={stepsForm}
      />
      <Divider />
      <LoadingSkeleton quantiyItens={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mt: "1rem",
          }}
        >
          <Box sx={{ display: "flex", columnGap: 1 }}>
            <ConditionalStepForm show={stepsForm > 2}>
              <Button
                variant="contained"
                type="button"
                color="dbPink"
                hidden={stepsForm === 1}
                onClick={handlePrevStepForm}
                data-cy="buttonPreviousStep"
              >
                Anterior
              </Button>
            </ConditionalStepForm>
            <ConditionalStepForm show={stepsForm === 1}>
              <Button
                variant="contained"
                type="button"
                color="dbLightBlue"
                onClick={handleSubmit(onSaveFirstStep)}
                disabled={
                  countErrors > 0 ||
                  ((!isDirty || !isValid) && (!isValid || !newCvId))
                }
                data-cy="proximo-passo-1"
              >
                Próximo
              </Button>
            </ConditionalStepForm>
            <ConditionalStepForm show={stepsForm > 2}>
              <Button
                variant="contained"
                type="button"
                color="dbLightBlue"
                onClick={handleNextStepForm}
                disabled={
                  (stepsForm != 4 && stepsForm != 3) ||
                  countErrors > 0 ||
                  (!nextStage)
                }
                data-cy="buttonNextStepsTwoToFour"
              >
                Próximo
              </Button>
            </ConditionalStepForm>
            <ConditionalStepForm show={stepsForm > 4}>
              <Button
                variant="contained"
                type="button"
                color="dbDarkBlue"
                data-cy="finalizar"
                onClick={() => navigate("/")}
              >
                Finalizar Cadastro
              </Button>
            </ConditionalStepForm>
          </Box>
        </Box>
      </LoadingSkeleton>
    </Box>
  );
};

export default FormCV;
