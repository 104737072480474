import { ThemeProvider } from "@mui/material/styles";
import { MsalProvider } from "@azure/msal-react";
import { AzureClientInstance } from "configuration";
import { useAppSelector } from "./configuration/reduxToolkit/hooks";
import { selectLoading, selectLanguage } from "./reduxFeatures/app/appSlice";

import themeMUI from "layouts/themeMUI";
import CssBaseline from "@mui/material/CssBaseline";
import Loading from "components/Loading";
import { Box } from "@mui/material";
import AlertMessage from "components/AlertMessage";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Router } from "configuration/index";

AzureClientInstance.handleRedirectPromise();

function App() {
  const loading = useAppSelector(selectLoading);
  const language = useAppSelector(selectLanguage);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  return (
    <Box>
      <Loading isLoading={loading} />
      <AlertMessage />
      <MsalProvider instance={AzureClientInstance}>
        <ThemeProvider theme={themeMUI}>
          <CssBaseline />
          <Router />
        </ThemeProvider>
      </MsalProvider>
    </Box>
  );
}

export default App;
