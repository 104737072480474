import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarCloseReason,
} from "@mui/material";
import React from "react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../configuration/reduxToolkit/hooks";
import { selectAlert, setAlert } from "../../reduxFeatures/app/appSlice";

type Props = {};

const AlertMessage = (props: Props) => {
  const dispatch = useAppDispatch();
  const alert = useAppSelector(selectAlert);

  const handleCloseAlert = (
    event: React.SyntheticEvent<any> | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setAlert({ ...alert, open: false }));
  };

  return (
    <Snackbar
      title={`${alert.type === "error" ? "Erro" : "Sucesso"} - ${
        alert.message
      }`}
      open={alert.open}
      autoHideDuration={alert.seconds}
      onClose={handleCloseAlert}
    >
      <Alert onClose={handleCloseAlert} severity={alert.type as AlertColor}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
