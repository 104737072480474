import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

interface IPrivateRouteProps {
  element: React.ElementType;
}

const PrivateRoute: React.FunctionComponent<IPrivateRouteProps> = ({
  element: Element,
}) => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? (
    <Element />
  ) : (
    <Navigate to="signIn" replace state={{ path: location.pathname }} />
  );
};

export default PrivateRoute;
