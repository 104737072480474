import React from "react";
import { useAppSelector } from "configuration/reduxToolkit/hooks";
import { selectLoading } from "reduxFeatures/app/appSlice";
import { Box, Skeleton } from "@mui/material";

type Props = {
  children: React.ReactNode;
  quantiyItens?: number;
  showSkeleton?: boolean;
};

const LoadingSkeleton = ({
  children,
  quantiyItens = 1,
  showSkeleton = true,
}: Props) => {
  const isLoading = useAppSelector(selectLoading);
  const arrayItens = Array.from(Array(quantiyItens).keys());

  return isLoading && showSkeleton ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        columnGap: "2",
        mt: "1rem",
        mb: "1rem",
      }}
    >
      {arrayItens.map((item) => (
        <Skeleton animation="wave" key={item} />
      ))}
    </Box>
  ) : (
    <>{children}</>
  );
};

export default LoadingSkeleton;
