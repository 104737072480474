import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../configuration/reduxToolkit/store";

interface Alert {
  open: boolean;
  type: AlertColor;
  message: string;
  seconds?: number;
}
export type TLanguage = "pt-BR" | "en-US";
interface AppState {
  loading: boolean;
  alert: Alert;
  language: TLanguage;
  countErrors: number;
}

const initialState: AppState = {
  loading: false,
  alert: {
    open: false,
    type: "error",
    message: "",
    seconds: 2000,
  },
  language: "pt-BR",
  countErrors: 0,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAlert: (state, action: PayloadAction<Alert>) => {
      state.alert = {
        ...state.alert,
        ...action.payload,
      };
    },
    setLanguage: (state, action: PayloadAction<TLanguage>) => {
      state.language = action.payload;
    },
    incrementCountErrors: (state) => {
      state.countErrors = state.countErrors + 1;
    },
    decrementCountErros: (state) => {
      state.countErrors = state.countErrors > 0 ? state.countErrors - 1 : 0;
    },
    resetCountErros: (state) => {
      state.countErrors = 0;
    },
  },
});

export const {
  setLoading,
  setAlert,
  setLanguage,
  incrementCountErrors,
  decrementCountErros,
  resetCountErros,
} = appSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLoading = (state: RootState) => state.app.loading;
export const selectAlert = (state: RootState) => state.app.alert;
export const selectLanguage = (state: RootState) => state.app.language;
export const selectCountErrors = (state: RootState) => state.app.countErrors;

export default appSlice.reducer;
