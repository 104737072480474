import EditCV from "pages/cv/edit";
import SignIn from "pages/signIn";
import MainLayout from "../../layouts/main/mainLayout";
import ListCVPage from "../../pages/cv/list";
import PrivateRoute from "./privateRoute";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FormCV from "components/CV/Form";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute element={MainLayout} />}>
          <Route path="/" element={<ListCVPage />} />
          <Route path="curriculo/create" element={<FormCV />} />
          <Route path="curriculo/edit/:id" element={<EditCV />} />
        </Route>
        <Route path="signIn" element={<SignIn />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
