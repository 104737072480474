import React from "react";
import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ITableList } from "services/cv/interfaces";
import LoadingSkeleton from "components/LoadingSkeleton";

type Props = {
  rows: ITableList[];
  columns: GridColDef[];
  pageSize?: number;
  currentPage: number;
  totalElements: number;
  handleEdit: (id: string) => void;
  handlePrint: (id: string) => void;
  handleDelete: (id: string) => void;
  handleNewPage: (page: number) => void;
};

const TableCV = ({
  rows,
  columns,
  pageSize = 2,
  handleDelete,
  handleEdit,
  handlePrint,
  currentPage,
  totalElements,
  handleNewPage,
}: Props) => {
  const columnActions: GridColDef = {
    field: "actions",
    headerName: "Ações",
    flex: 1,
    sortable: false,
    maxWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams) => (
      <Box sx={{ display: "flex" }}>
        <IconButton
          color="dbDarkBlue"
          aria-label="Editar"
          onClick={() => handleEdit(params.row.id)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="dbDarkBlue"
          aria-label="Imprimir"
          onClick={() => handlePrint(params.row.id)}
        >
          <PictureAsPdfIcon />
        </IconButton>
        <IconButton
          color="dbRed"
          aria-label="Excluir"
          onClick={() => handleDelete(params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    ),
  };

  return (
    <Box sx={{ mt: "1rem", display: "flex", height: "100%" }}>
      <Box sx={{ flexGrow: 1 }}>
        <LoadingSkeleton quantiyItens={totalElements > 0 ? totalElements : 20}>
          <DataGrid
            autoHeight
            disableColumnMenu
            disableColumnFilter
            rows={rows}
            rowCount={totalElements ?? 0}
            columns={columns.concat(columnActions)}
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
            paginationMode="server"
            page={currentPage}
            onPageChange={(newPage) => handleNewPage(newPage)}
          />
        </LoadingSkeleton>
      </Box>
    </Box>
  );
};

export default TableCV;
