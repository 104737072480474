import { Chip, FormHelperText, Grid, SelectChangeEvent, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { ILanguagesFront } from "services/cv/interfaces";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../configuration/reduxToolkit/hooks";
import {
  setLoading,
  setAlert,
  incrementCountErrors,
  decrementCountErros,
  selectLanguage,
} from "../../../../reduxFeatures/app/appSlice";

import { FactoryRemoteLanguages } from "services/combos/remoteLanguages";
import { FactoryRemoteLevelLanguage } from "services/combos/remoteLevelLanguage";
import { LanguageTextByAcronym } from "shared/utils";
import LoadingSkeleton from "../../../LoadingSkeleton";
import { useTranslation } from "react-i18next";
import { IBaseCombosFront } from "services/combos/interfaces";
import { ComboMapper } from "services/combos/comboMapper";
import SelectByObject from "components/SelectByObject";

type Props = {
  languages: ILanguagesFront[];
  setLanguages: (v: ILanguagesFront[]) => void;
};

const LANGUAGE_INIT: ILanguagesFront = {
  active: false,
  id: "",
  level: {
    description: "",
    id: "",
    language: "",
  },
  language: {
    description: "",
    id: "",
    language: "",
  },
}

const ACTIVE_LANGUAGE_INIT: IBaseCombosFront = {
  description: "",
  id: "",
  language: ""
};
const ACTIVE_LEVEL_INIT: IBaseCombosFront = {
  description: "",
  id: "",
  language: ""
};

const Languages = ({ languages, setLanguages }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const langAcronym = useAppSelector(selectLanguage);
  const langDescription = LanguageTextByAcronym[langAcronym];

  const [activeLanguage, setActiveLanguage] = useState<IBaseCombosFront>(ACTIVE_LANGUAGE_INIT);
  const [activeLevel, setActiveLevel] = useState<IBaseCombosFront>(ACTIVE_LEVEL_INIT);

  const [listLanguages, setListLanguages] = useState<IBaseCombosFront[]>([]);
  const [listaLanguagesLevel, setListaLanguagesLevel] = useState<
    IBaseCombosFront[]
  >([]);

  const [languageOnEdit, setLanguageOnEdit] = useState<ILanguagesFront>(LANGUAGE_INIT);

  const getDataCombos = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const langs = await FactoryRemoteLanguages.get();
      const level = await FactoryRemoteLevelLanguage.get();
      const listLangs = langs.filter(
        (item) => item.linguagem.toLowerCase() === langDescription
      );
      const levelLang = level.filter(
        (item) => item.linguagem.toLowerCase() === langDescription
      );
      setListLanguages(ComboMapper.arrayModelToDomain(listLangs));
      setListaLanguagesLevel(ComboMapper.arrayModelToDomain(levelLang));
      dispatch(setLoading(false));
      dispatch(decrementCountErros());
    } catch (e: any) {
      dispatch(setLoading(false));
      dispatch(incrementCountErrors());
      dispatch(setAlert({ type: "error", open: true, message: e.message }));
    }
  }, [dispatch, langDescription]);

  useEffect(() => {
    getDataCombos();
  }, [getDataCombos]);

  const handleActiveLanguage = (event: SelectChangeEvent) => {
    const [selected] = listLanguages.filter(
      (item) => item.id === event.target.value
    );

    const existsLanguage = languages.some(
      (lang) => {
        return lang.language.description === selected.description
      }
    );

    if (existsLanguage && languageOnEdit.id === "") {
      setActiveLanguage(ACTIVE_LANGUAGE_INIT);
      setActiveLevel(ACTIVE_LEVEL_INIT);
      dispatch(
        setAlert({
          open: true,
          message:
            "Já existe um nível como esse adicionado. Por favor, selecione outro idioma.",
          type: "error",
          seconds: 3000,
        })
      );
    }

    setActiveLanguage(selected);
  };

  const handleLevelLanguage = (event: SelectChangeEvent) => {
    const [activeLevel] = listaLanguagesLevel.filter(
      (item) => item.id === event.target.value
    );

    const existsLanguage = languages.some(
      (item: ILanguagesFront) =>
        item.language.description === activeLanguage.description
    );

     if (!existsLanguage && languageOnEdit.id === "") {
      const newLanguage: ILanguagesFront = {
        id: "",
        active: true,
        language: activeLanguage,
        level: activeLevel,
      }
      setLanguages([...languages, newLanguage]);
      setActiveLanguage(ACTIVE_LANGUAGE_INIT);
      setActiveLevel(ACTIVE_LEVEL_INIT);
    }

    else if (existsLanguage) {
      try {
        const editedLanguage = languages.findIndex(language => language.id === languageOnEdit.id);

        languages[editedLanguage].language = activeLanguage;
        languages[editedLanguage].level = activeLevel;

        setActiveLanguage(ACTIVE_LANGUAGE_INIT);
        setActiveLevel(ACTIVE_LEVEL_INIT);
        setLanguageOnEdit(LANGUAGE_INIT);

      } catch (error) {
        dispatch(
          setAlert({
            open: true,
            message:
              "Algo deu errado. Por favor, tente novamente.",
            type: "error",
            seconds: 3000,
          })
        );
        setActiveLanguage(ACTIVE_LANGUAGE_INIT);
        setActiveLevel(ACTIVE_LEVEL_INIT);
        setLanguageOnEdit(LANGUAGE_INIT);
      }
    }
  }

  const handleSetLanguageBack = (item: ILanguagesFront) => {
    setActiveLanguage(item.language);
    setActiveLevel(item.level);
    setLanguageOnEdit(item);

    // TODO: sinalizar no chip qual idioma está sendo editado
  }

  const removeLanguage = (item: ILanguagesFront) => {
    dispatch(setLoading(true));
    try {
      setLanguages([
        ...languages.filter(
          (lang) =>
            !(
              lang.language.description === item.language.description &&
              lang.level.description === item.level.description
            )
        ),
      ]);
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          open: true,
          message: "Idoma removido com sucesso.",
          type: "success",
          seconds: 2000,
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          message: "Não foi possível remover o idioma! Favor tentar novamente.",
          type: "error",
          seconds: 2000,
        })
      );
      dispatch(setLoading(false));
    }
  };

  return (
    <Box>
      <LoadingSkeleton quantiyItens={5}>
        <Grid container spacing={4} sx={{ mt: "0.5rem", mb: "2rem" }}>
          <Grid container item xs={6} spacing={2}>
            <Grid item xs={6}
              data-cy="select-idiomas"
            >
              <SelectByObject
                label={t("cvForm.labels.mainLanguage")}
                name="activeLanguage"
                rows={listLanguages}
                value={activeLanguage.id}
                handleSelect={handleActiveLanguage}
                keyNameDescription="description"
                keyNameValueSelected="id"
              />
              <Box hidden={languages.length !== 0}>
                <FormHelperText
                  data-cy='texto-ajuda-conhecimento'
                  sx={{ ml: 1 }}
                >
                  Insira ao menos um idioma.
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={6}
              data-cy="nivel-idioma"
            >
              <SelectByObject
                label={t("cvForm.labels.level")}
                name="activeLanguage"
                rows={listaLanguagesLevel}
                value={activeLevel.id}
                handleSelect={handleLevelLanguage}
                disabled={activeLanguage.description === ""}
                keyNameDescription="description"
                keyNameValueSelected="id"
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={1}>
              {languages.map((item, index) => (
                <Chip
                  key={index}
                  label={`${item.language.description}-${item.level.description}`}
                  onDelete={() => removeLanguage(item)}
                  onClick={() => handleSetLanguageBack(item)}
                  color="dbDarkBlue"
                  variant="outlined"
                  data-cy="idioma-e-nivel"
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </LoadingSkeleton>
    </Box>
  );
};

export default Languages;