import {formatDateStringFromDateStringEn} from "shared/utils";
import {IEducations, IEducationsFront} from "../interfaces";

interface IEducationsMapper {
  toDomain: (data: IEducations) => IEducationsFront;
  toModel: (data: IEducationsFront) => IEducations;
}

const convertModelToDomain = (data: IEducations): IEducationsFront => {
  return {
    id: data.id,
    cvId: data.cvId,
    name: data.nomeInstituicao,
    course: data.curso,
    dateInit: data.dataInicio,
    dateEnd: data.dataTermino,
    activities: data.atividades,
    situation: {
      description: data.situacao.descricao,
      id: data.situacao.id,
      language: data.situacao.linguagem,
    },
    step: data.step,
  };
};

const convertDomainToModel = (data: IEducationsFront): IEducations => {
  return {
    id: data.id,
    cvId: data.cvId,
    nomeInstituicao: data.name,
    curso: data.course,
    dataInicio: formatDateStringFromDateStringEn(data.dateInit),
    dataTermino: formatDateStringFromDateStringEn(data.dateEnd),
    atividades: data.activities,
    situacao: {
      descricao: data.situation.description,
      id: data.situation.id,
      linguagem: data.situation.language,
    },
    step: data.step,
  };
};

export const EducationMapper: IEducationsMapper = {
  toDomain: convertModelToDomain,
  toModel: convertDomainToModel,
};
