import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

type Props = {
  title: string;
};

const Header = ({ title }: Props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <Typography
            textTransform="uppercase"
            variant="h6"
            mx="auto"
            color="darkblue"
            fontSize={20}
            fontWeight="bold"
            sx={{ mt: "1rem" }}
          >
            {title}
          </Typography>
        </Box>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Header;
