import { createTheme } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
declare module "@mui/material/styles" {
  interface Palette {
    dbDarkBlue: Palette["primary"];
    dbPink: Palette["primary"];
    dbRed: Palette["primary"];
    dbYellow: Palette["primary"];
    dbGreen: Palette["primary"];
    dbLightBlue: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    dbDarkBlue?: PaletteOptions["primary"];
    dbPink?: PaletteOptions["primary"];
    dbRed?: PaletteOptions["primary"];
    dbYellow?: PaletteOptions["primary"];
    dbGreen?: PaletteOptions["primary"];
    dbLightBlue?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/Fab" {
  interface FabPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/FormControl" {
  interface FormControlPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/FormLabel" {
  interface FormLabelPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/Icon" {
  interface IconPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/Pagination" {
  interface PaginationPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

declare module "@mui/material/Radio" {
  interface RadioPropsColorOverrides {
    dbDarkBlue: true;
    dbPink: true;
    dbRed: true;
    dbYellow: true;
    dbGreen: true;
    dbLightBlue: true;
  }
}

const themeMUI = createTheme(
  {
    palette: {
      dbDarkBlue: {
        main: "#221F52",
        contrastText: "#fff",
      },
      dbPink: {
        main: "#ED0973",
        contrastText: "#fff",
      },
      dbRed: {
        main: "#FF1229",
        contrastText: "#fff",
      },
      dbYellow: {
        main: "#FFB400",
        contrastText: "#fff",
      },
      dbGreen: {
        main: "#42CE95",
        contrastText: "#fff",
      },
      dbLightBlue: {
        main: "#14B6EF",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: "TTSupermolot-Regular",
    },
  },
  ptBR
);

export default themeMUI;
