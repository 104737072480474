import { AxiosClient } from "configuration/axios/client";
import {
  HttpClient,
  HttpMessageError,
  HttpStatusCode,
} from "configuration/http/httpClient";
import { IPutExperienciesCV, IExperiences } from "../interfaces";

export class RemoteExperienciesPutCV implements IPutExperienciesCV {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IExperiences>
  ) {}

  async put(id: string, data: IExperiences): Promise<IExperiences> {
    const response = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "put",
      body: data,
    });

    const remoteData = response.body || ({} as IExperiences);
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return remoteData;
      case HttpStatusCode.created:
        return remoteData;
      case HttpStatusCode.noContent:
        return {} as IExperiences;
      case HttpStatusCode.badRequest:
        throw new Error(HttpMessageError[400]);
      default:
        throw new Error(HttpMessageError[500]);
    }
  }
}

const FactoryExperienciesPutCV: IPutExperienciesCV =
  new RemoteExperienciesPutCV("/experiencias", new AxiosClient());

export { FactoryExperienciesPutCV };
